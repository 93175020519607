export default {
  UPDATE_APP_CONFIG: 'UPDATE_APP_CONFIG',
  UPDATE_PRODUCT_RULES: 'UPDATE_PRODUCT_RULES',
  REGISTER_CLIENT_APP: 'REGISTER_CLIENT_APP',
  SET_LOADED: 'SET_LOADED',
  UPDATE_STATE: 'UPDATE_STATE',
  TOGGLE_ACTIVE_BALLS: 'TOGGLE_ACTIVE_BALLS',
  CREATE_BALLS: 'CREATE_BALLS',
  UPDATE_BETS: 'UPDATE_BETS',
  RESET_BETS: 'RESET_BETS',
  RESET_BET: 'RESET_BET',
  CREATE_BET: 'CREATE_BET',
  RESET_SELECTION: 'RESET_SELECTION',
  ADD_TO_TICKET: 'ADD_TO_TICKET',
  REMOVE_FROM_TICKET: 'REMOVE_FROM_TICKET',
  CLEAR_TICKET: 'CLEAR_TICKET',
  CREATE_RANDOM_NUMBERS: 'CREATE_RANDOM_NUMBERS',
  TOGGLE_COLORS: 'TOGGLE_COLORS',
  SET_PALETTE: 'SET_PALETTE',
  BETTING_ENABLED: 'BETTING_ENABLED',
  UPDATE_PLAYER_BALANCE: 'UPDATE_PLAYER_BALANCE',
  UPDATE_PLAYER_TOKEN: 'UPDATE_PLAYER_TOKEN',
  UPDATE_ROUND: 'UPDATE_ROUND',
  DECREASE_ROUND: 'DECREASE_ROUND',
  RESET_BALLS: 'RESET_BALLS',
  COLORIZED_BALLS: 'COLORIZED_BALLS',
  RESET_COLOR_GROUPS: 'RESET_COLOR_GROUPS',
  SELECT_ALL_COLORS: 'SELECT_ALL_COLORS',
  RESET_SPECIAL_BETS: 'RESET_SPECIAL_BETS',
  CHECK_PAYIN: 'CHECK_PAYIN',
  SET_PRODUCT_STATS: 'SET_PRODUCT_STATS',
  SET_PRODUCT_RESULTS: 'SET_PRODUCT_RESULTS',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  CREATE_PREBALL: 'CREATE_PREBALL',
  SELECT_COLOR_GROUP: 'SELECT_COLOR_GROUP',
  ALLOW_REQUEST: 'ALLOW_REQUEST',
  UPDATE_PLAYER_INFO: 'UPDATE_PLAYER_INFO',
  TOGGLE_TICKET_HISTORY: 'TOGGLE_TICKET_HISTORY',
  REBET: 'REBET',
  CLOSE_ERROR_OVERLAY: 'CLOSE_ERROR_OVERLAY',
  LAST_TICKETS: 'LAST_TICKETS',
  TICKET_HISTORY: 'TICKET_HISTORY',
  SHOW_HIDE_TICKET_HISTORY: 'SHOW_HIDE_TICKET_HISTORY',
  SET_SPECIAL_BET_VISIBILITY: 'SET_SPECIAL_BET_VISIBILITY',
  SET_SPECIAL_BET_ODDS: 'SET_SPECIAL_BET_ODDS',
  CANCEL_TICKET: 'CANCEL_TICKET',
  UPDATE_FUTURE_BETS: 'UPDATE_FUTURE_BETS',
  BETTING_DISABLED_EVENT: 'BETTING_DISABLED_EVENT',
  SET_POP_ENDPOINT: 'SET_POP_ENDPOINT',
  LOAD_PLUGINS: 'LOAD_PLUGINS',
  SET_PLUGIN_SNIPPETS: 'SET_PLUGIN_SNIPPETS',
  TOGGLE_AUDIO: 'TOGGLE_AUDIO',
  SET_DRAWN_BALLS: 'SET_DRAWN_BALLS',
  TOGGLE_STATS: 'TOGGLE_STATS',
  SWITCH_THEME: 'SWITCH_THEME',
  QUICK_PAYIN: 'QUICK_PAYIN',
  LOCK_BETTING: 'LOCK_BETTING',
  LAST_TICKETS_IN_PROGRESS: 'LAST_TICKETS_IN_PROGRESS',
  TICKET_HISTORY_IN_PROGRESS: 'TICKET_HISTORY_IN_PROGRESS',
  SET_TICKET_HISTORY_RANGE_ERROR: 'SET_TICKET_HISTORY_RANGE_ERROR',
  SET_FULL_SCREEN_STATUS: 'SET_FULL_SCREEN_STATUS',
  SET_LOCALIZED_TRANSLATIONS: 'SET_LOCALIZED_TRANSLATIONS',
  TOGGLE_NEW_FEATURES: 'TOGGLE_NEW_FEATURES',
  SET_THIRD_PARTY_TOKEN: 'SET_THIRD_PARTY_TOKEN',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_PREBALL_BET_ODD: 'SET_PREBALL_BET_ODD',
};
