import axios from 'axios';
import { shuffle } from 'lodash';
import { routes } from '../../config/index';

const DP = '/get-lb';

export default {
  shuffledRoutes() {
    console.log('routesss: ', routes);
    return shuffle(routes.gcmApi);
  },
  getRoute() {
    const api = this.shuffledRoutes()[0] + DP;
    return axios.get(api)
      .then((response) => response.data);
  },
};
