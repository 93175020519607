<template>
  <div class="draw-details-wrapper">
    <div class="tabs">
      <div class="tab"
           v-for="(tab, key) in drawDetailsTabs"
           :key="key"
           :class="[{active: key === selectedTab}, {'disabled': tab.disabled}]"
           :disabled="tab.disabled"
           @click="selectTab(tab, key)">
        {{tab.name | translate}}
      </div>
    </div>
    <div class="draw-details-body">
      <component :is="activeComponent"></component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Statistics from './Statistics';
import Results from './Results';
import Feed from './Feed';
import GameHelp from '../common/GameHelp';
import Boost from '../common/Boost';

export default {
  name: 'DrawDetailsWrapper',
  components: {
    Statistics,
    Results,
    Feed,
    GameHelp,
    Boost,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTab: 0,
      activeComponent: '',
      drawDetailsTabs: [],
      ball: {
        id: null,
        number: null,
        active: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'player',
    ]),
  },
  methods: {
    selectTab(tab, key) {
      if (!tab.disabled) {
        this.selectedTab = key;
        this.activeComponent = tab.component;
      }
    },
  },
  created() {
    if (this.$route.name === 'drawDetails') {
      this.drawDetailsTabs = [
        {
          name: 'general_statistics',
          component: 'Statistics',
        },
        {
          name: 'general_results',
          component: 'Results',
        },
      ];
    } else {
      this.drawDetailsTabs = this.tabs;
    }
    this.activeComponent = this.drawDetailsTabs[0].component;
  },
  watch: {
    'player.id': function reinitializeActiveComponent() {
      this.activeComponent = '';
      setTimeout(() => {
        this.activeComponent = this.drawDetailsTabs[0].component;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
  .draw-details-wrapper {
    width: 100%;

    .tabs {
      line-height: 40px;
      color: var(--text-primary-1);
      font-size: 0.875em;
      border-radius: 2px;
      text-align: center;
      display: flex;
      justify-content: space-between;

      .tab {
        float: left;
        width: 100%;
        cursor: pointer;
        border-bottom: 2px solid var(--tab-border-color);
        background-color: var(--tab-bg, var(--card-bg));
        transition: all .5s ease;

        &:hover {
          background-color: var(--tab-hover-bg);
        }

        &:active {
          background-color: var(--tab-pressed-bg);
        }

        &.active {
          border-bottom: 2px solid var(--accent-bg, var(--brand-bg));
          color: var(--accent-bg, var(--brand-bg));
        }

        &.disabled, &:disabled {
          background-color: var(--tab-pressed-bg);
          color: var(--text-primary-3);
        }
      }
    }

    .draw-details-body {
      float: left;
      width: 100%;
      background-color: var(--event-details-bg, var(--card-bg));
      text-align: center;
      padding: 8px;
      padding-bottom: 50px;
    }
  }
</style>
