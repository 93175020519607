const tenantByUuid = (uuid) => {
  let tenant = '';

  switch (uuid) {
    case 'b99752b3-443c-4c80-b559-945a95c4b805':
      tenant = 'prvafirma';
      break;
    case '5847f0c1-2b87-4aab-a83e-01b9185b769b':
      tenant = 'balkanbetstaging';
      break;
    case '2f9aacfb-1cd6-4868-ac67-fcf8d0af8970':
      tenant = 'qa';
      break;
    case 'f0a2c9ff-cbf7-417e-b9ec-867c27bbe9ca':
      tenant = 'expo';
      break;
    case '8f930039-9ccc-48e8-b33a-65a89cf7ce79':
      tenant = 'demo';
      break;
    case 'cb698330-3391-426e-b99d-eb1c77ad20a2':
      tenant = 'betvilistaging';
      break;
    case 'be410524-8a0e-4bdd-bdc8-4ae33a59d645':
      tenant = 'futurebet';
      break;
    case 'fe027b61-e6f4-46ec-9200-0e37e8ab0910':
      tenant = 'bethappyeurstaging';
      break;
    case '757039a1-6790-4c7e-b831-74bb6023f078':
      tenant = 'bethappyusdstaging';
      break;
    case '5505feb6-0029-4c5b-92e0-3580e16b0150':
      tenant = 'bethappytrystaging';
      break;
    case 'a279aff3-ec03-490f-a597-2eeb51896863':
      tenant = 'bmk';
      break;
    case '4f54c6aa-82a9-475d-bf0e-dc02ded89225':
      tenant = 'balkanbet';
      break;
    case '7cc40a65-5b0a-41e1-bd42-49c8670f0c51':
      tenant = 'boulparyaj';
      break;
    case 'ace1a8a6-a772-45b9-bb01-e0cfc6b38632':
      tenant = 'admiral2';
      break;
    case '4edfe9a8-3e26-40ce-ae4a-450ed857e87b':
      tenant = 'wettnet';
      break;
    case '3ae102f7-9fb2-4e28-b50c-e3edd074ef7c':
      tenant = 'bugbetstaging';
      break;
    case '4cf3a3b0-4b74-4331-a8c0-e6cce2df18c1':
      tenant = 'piramida';
      break;
    case '0cda45b1-06ba-4684-b265-46a1cc00652c':
      tenant = 'fairplay';
      break;
    case 'dc0b9a32-e90a-4a1d-b983-b93151b57037':
      tenant = 'sigma';
      break;
    case '3654ad09-2f1f-4ca7-a284-9e165855fb4a':
      tenant = 'maxbetsrbstaging';
      break;
    case 'a339cfaa-ab0f-4d70-98d8-37d4385a30b7':
      tenant = 'noktabet';
      break;
    case '91eab3ea-a16c-44bf-bb4a-07fce18625c9':
      tenant = 'cashwin';
      break;
    case 'c7fcd69f-37c6-432a-a4d2-5ff56b80bd2a':
      tenant = 'gameofbet';
      break;
    case '3946b6d9-1b5f-4780-8966-1e20bd167aff':
      tenant = 'circus';
      break;
    case '1c1c7c17-04b8-4333-bf94-7f6df790882f':
      tenant = 'atlasbet';
      break;
    case '4a43a766-b675-4c58-8b0c-3f13ee4d039d':
      tenant = 'betviliproduction';
      break;
    case '27b46c31-f347-4fb3-8d89-dab559851452':
      tenant = 'oynabil';
      break;
    case '27a9f10c-566b-480b-9e30-1209063aa27e':
      tenant = 'betvilixmbproduction';
      break;
    case '50393a61-e70c-4a98-82cb-68643b517bc4':
      tenant = 'betvilixmbstaging';
      break;
    case '846a24db-2c36-4f70-8f1d-4062ed1b074a':
      tenant = 'circusstaging';
      break;
    case '62a0d8eb-23ec-420e-aff8-05050b9c0968':
      tenant = 'premiumbahis';
      break;
    case 'e84340e3-cd6f-4510-97fd-5b00d69d95a0':
      tenant = 'premiumbahistry';
      break;
    case 'e4259f08-0607-4ddc-9686-2ae0782c36c2':
      tenant = 'premiumbahisusd';
      break;
    case 'd40b8bcd-cbd2-4ee0-aa16-a2d1aeb4cf37':
      tenant = 'redfoxbet';
      break;
    case '7a334bfc-5449-4c0f-9428-71c75070196b':
      tenant = 'betasus';
      break;
    case '106d6b15-0280-4255-bf02-e14e113b1686':
      tenant = 'atlasstaging';
      break;
    case '1de0cfad-b2d9-40cd-b6cf-d2bbb151862f':
      tenant = 'kombobet';
      break;
    case '6ed6d9ab-415d-45a4-b623-daf36e332c55':
      tenant = 'paryerenovacash';
      break;
    case 'eedf2d50-5998-417a-b1c0-76e35984fe9b':
      tenant = 'vbeteur';
      break;
    case '313dcd1f-0543-4290-8fcf-ffe507740283':
      tenant = 'vbetbrl';
      break;
    case 'c9994afb-699f-41c8-b484-6e58bad7eb51':
      tenant = 'vbetcad';
      break;
    case 'bd3f8b2c-7f8e-4dfb-830d-e399225cc76d':
      tenant = 'vbetcny';
      break;
    case '62d369e4-2753-4d7c-bf48-89634fabee69':
      tenant = 'vbetgel';
      break;
    case 'c53e7540-80ea-43b3-a0d4-ac26d35b5016':
      tenant = 'vbetkzt';
      break;
    case 'fb3852a1-6b01-4f56-8948-d3d30a7ee882':
      tenant = 'vbetpln';
      break;
    case '112ceab4-4055-40bc-bdad-7a6b35e65c49':
      tenant = 'vbetrub';
      break;
    case '1b5f6efd-2296-4cd1-93dc-b9554c5af461':
      tenant = 'vbetsek';
      break;
    case '6ddd317f-2da7-48ef-b8b0-01fdbc01dfa1':
      tenant = 'vbettry';
      break;
    case '18540acb-6d90-4487-9b77-a98c4c0437af':
      tenant = 'vbetusd';
      break;
    case 'cc672299-3314-4343-a87d-2f70d7256bd2':
      tenant = 'superbetstaging';
      break;
    case '7f38c596-5e18-4eba-a94f-feba42432938':
      tenant = 'superbet';
      break;
    case '4663dd61-5791-4bc4-9d58-f0587f8177bb':
      tenant = 'wbrostaging';
      break;
    case 'b9639a9a-fd94-46e3-b411-173cd512d6d3':
      tenant = 'wbro';
      break;
    case 'f47c7826-2fd5-491e-bebf-80b7b33d1f4b':
      tenant = 'mybetplus';
      break;
    case '479dde21-5af9-4ade-8c52-bbf8de78efae':
      tenant = 'avaloneur';
      break;
    case 'eb1c913d-f5b0-4b88-a82f-5f2cba91f85f':
      tenant = 'avalonusd';
      break;
    case '9ea03712-8210-4fef-a78f-11f2411c7f4b':
      tenant = 'avalonbyn';
      break;
    case 'e2382efc-d3b3-44a4-9ebb-e4dddf3236b0':
      tenant = 'avalonrub';
      break;
    case 'e0ae3630-3ac7-466a-8c08-6e9e10b8eff1':
      tenant = 'qq288usd';
      break;
    case '266de800-f2a1-489a-ab1d-3fca666cb346':
      tenant = 'qq288cny';
      break;
    case 'fe023139-faaf-4c88-b4f2-2bcd02d9af7c':
      tenant = 'qq288thb';
      break;
    case 'c3d57afc-b8dc-40e0-b466-1e1953a99b97':
      tenant = 'qq288myr';
      break;
    case 'aa0cca71-bce5-4d9f-a3ce-672a54d7c94b':
      tenant = 'qq288vnd';
      break;
    case '7003bc7d-27b4-4492-af9d-5a72d43267a7':
      tenant = 'qq288idr';
      break;
    case '74077457-63a8-4608-b3a1-bacdf38f4a42':
      tenant = 'qq288krw';
      break;
    case '9420139a-cf90-4367-ae66-29c2d1bb0b6e':
      tenant = 'victoryeur';
      break;
    case 'bc69e442-c378-42db-b240-0a016066931d':
      tenant = 'victoryusd';
      break;
    case '9efb1417-4376-49d0-b766-33de940422b8':
      tenant = 'victoryngn';
      break;
    case '8c59c978-b801-4d9b-b769-e0e37bbe5b8d':
      tenant = 'victory2usd';
      break;
    case '64ea5763-ce5c-4a5c-a94f-a888549a1002':
      tenant = 'victory2eur';
      break;
    case 'ee010c47-eb96-4ffe-a11f-bf2736607193':
      tenant = 'victory2brl';
      break;
    case '2ee37422-d130-49eb-92ef-98a6151b71bb':
      tenant = 'betconstruct';
      break;
    case '5f49d073-8ee2-45b3-8937-cf6db00ffb05':
      tenant = 'betconstructeur';
      break;
    case '8e4dd497-6406-4655-a283-13cdf2e6e04f':
      tenant = 'betconstructazn';
      break;
    case '61d76a87-6eb1-4147-b6bb-7c40e0d3f49f':
      tenant = 'betconstructbrl';
      break;
    case '988564b5-d1e9-459c-860b-6b23e1ad5d58':
      tenant = 'betconstructcad';
      break;
    case '55359140-0001-43fa-9e0e-533d90aaff53':
      tenant = 'betconstructcny';
      break;
    case '543d9454-715d-4712-842e-64418b94f911':
      tenant = 'betconstructgbp';
      break;
    case '68b061b2-bd18-4c37-9bf7-62067f57be1d':
      tenant = 'betconstructgel';
      break;
    case '4c0b8dcd-ac1c-4669-9ad0-d137d7ac136b':
      tenant = 'betconstructirr';
      break;
    case 'a5b1f208-5612-43d6-a53e-006f6f73c203':
      tenant = 'betconstructkrw';
      break;
    case '4c47beaa-25c7-446f-93dc-94ea396c85e0':
      tenant = 'betconstructkzt';
      break;
    case 'e16c7aa7-d4f0-48fc-9ed8-d7ce2dae1c16':
      tenant = 'betconstructpln';
      break;
    case '89168d0c-38dd-46c0-9132-346b8f4d44f6':
      tenant = 'betconstructrub';
      break;
    case '9e1aef3d-53f6-40cc-af80-c638c9d08cc3':
      tenant = 'betconstructsek';
      break;
    case '9c118993-4cd3-45db-91ef-682bf9055f6c':
      tenant = 'betconstructtry';
      break;
    case 'efbb5924-f1c9-4f05-88f2-48e5c5613db7':
      tenant = 'betconstructuah';
      break;
    case '9a83f7c2-ec73-4e49-abd3-6f02adeee644':
      tenant = 'betconstructusd';
      break;
    case '72956331-2627-4838-a664-188438c225f6':
      tenant = 'betconstructamd';
      break;
    case '672ecac8-f6b8-430a-b557-01777486c5e3':
      tenant = 'betconstructbyn';
      break;
    case 'ccbc34fc-a0c0-4e8a-bd66-8549424e1aa7':
      tenant = 'betconstructuzs';
      break;
    case '9b1eec5a-663e-45d7-bc6e-68dab7c4e91f':
      tenant = 'betconstructmad';
      break;
    case 'dcc76b2e-10af-4896-bc10-ba4092c010c4':
      tenant = 'betconstructnad';
      break;
    case 'a85e9533-f057-40d1-898b-9356bce1b209':
      tenant = 'blueoceangaming';
      break;
    case '2ce92fdd-b078-419c-a51e-b0119dc227be':
      tenant = 'blueoceangamingusd';
      break;
    case 'fc3e1228-cdeb-4ced-91e9-80e449f76c53':
      tenant = 'blueoceangamingall';
      break;
    case '21c9276a-82aa-431e-b369-379ef61feb29':
      tenant = 'blueoceangamingars';
      break;
    case '0182be0c-2514-488e-9c77-92b98b1b4d0c':
      tenant = 'blueoceangamingaud';
      break;
    case '20493532-10bb-49c9-8c94-f92fdb2cd441':
      tenant = 'blueoceangamingazn';
      break;
    case 'ac11e4aa-3877-4de0-a843-58c055a2bdb0':
      tenant = 'blueoceangamingcad';
      break;
    case '74be347b-4306-4fce-b004-b03efdc5bb7d':
      tenant = 'blueoceangamingchf';
      break;
    case '9b3103f1-bb3e-4791-aeea-fac2c771f4a1':
      tenant = 'blueoceangamingeur';
      break;
    case 'ae61512f-8c26-427a-9875-a22c3e84d82a':
      tenant = 'blueoceangaminggbp';
      break;
    case '280c3395-be7a-4d71-83fa-0ac0df28c609':
      tenant = 'blueoceangamingnok';
      break;
    case '61235c44-89ff-4e76-9eb0-771ba404a62e':
      tenant = 'blueoceangamingnzd';
      break;
    case '171e2083-94ae-4739-95e1-5cc13f98b4fc':
      tenant = 'blueoceangamingpen';
      break;
    case '3a3be77f-bad3-44c0-9249-1ddf35a1e991':
      tenant = 'blueoceangamingphp';
      break;
    case '7206fc20-f2a1-43cd-be81-031a42c645ec':
      tenant = 'blueoceangamingpln';
      break;
    case '88998621-d855-4d77-ab8a-454811d3ddf3':
      tenant = 'blueoceangamingrsd';
      break;
    case 'a7795791-d11b-4a61-a6b3-4e346465b522':
      tenant = 'blueoceangamingrub';
      break;
    case '3bbaa743-e960-4539-a034-67f1d373f92d':
      tenant = 'blueoceangamingtnd';
      break;
    case '727ce66b-db55-46cd-b9c9-d30a40859411':
      tenant = 'blueoceangamingtry';
      break;
    case 'bdc58b65-6499-4345-b28a-6f50faf3ec45':
      tenant = 'blueoceangamingves';
      break;
    case '97f099fe-d024-4394-ba15-9009a45e3c42':
      tenant = 'blueoceangamingxmb';
      break;
    case '2a8d7984-3f71-43b5-9f20-7b3313050291':
      tenant = 'blueoceangamingbam';
      break;
    case '26c7e0d1-6bae-4e84-b092-1e13f2027e5d':
      tenant = 'blueoceangamingttd';
      break;
    case 'a94bf02e-dedd-4853-8c66-537dc8927793':
      tenant = 'blueoceangaminghkd';
      break;
    case '853d5798-78be-4521-9dde-b91996b639bb':
      tenant = 'sportingbookmakersstaging';
      break;
    case '4d1bce54-147c-4793-91a4-6ed30dc609fd':
      tenant = 'atlasusdstaging';
      break;
    case '296e2d30-ce76-4a17-b4fb-d83d7a9d07fa':
      tenant = 'betworld247staging';
      break;
    case '7dfcd31b-4b22-4349-ac3e-2801284c60ad':
      tenant = 'betworld247';
      break;
    case '5edfd376-7647-45a6-b702-dc616136ce0a':
      tenant = 'pionirstaging';
      break;
    case 'c5c98a58-ab58-40ca-8921-a5c5462dfa62':
      tenant = 'atlas';
      break;
    case '4c86c1db-df67-48b0-a22a-47c89b8963d0':
      tenant = 'atlasusd';
      break;
    case '7925c461-82db-46de-86ca-04ba95090216':
      tenant = 'atlasbrl';
      break;
    case 'eafe01c2-0dff-49bc-8f4e-e82cfb59e921':
      tenant = 'atlascad';
      break;
    case 'ea251fe6-db63-45eb-a90c-8ec50da9cfd0':
      tenant = 'atlasclp';
      break;
    case 'c62bfb44-5a50-43e4-a431-9abb86e8a54d':
      tenant = 'atlaseur';
      break;
    case '71b4857e-4d74-44e3-b6f7-adb941c082a4':
      tenant = 'atlasgbp';
      break;
    case 'd9ddf169-c97a-4638-8629-696d4edc3e3e':
      tenant = 'atlasinr';
      break;
    case '3e9c5d55-939c-4901-8472-28a62d0ac01c':
      tenant = 'atlasjpy';
      break;
    case 'bb432e14-a3b9-49e9-a551-0a085932dc48':
      tenant = 'atlaskzt';
      break;
    case 'c637c324-ac24-4006-a563-a72ad91b6079':
      tenant = 'atlasmxn';
      break;
    case '5fa884de-2fed-457d-979c-2b4594bf5de5':
      tenant = 'atlaspen';
      break;
    case '02867cb9-b981-4300-be83-8f3aca4bcd08':
      tenant = 'atlasrub';
      break;
    case '2d5c95e5-e530-418e-8b75-c175798cbc86':
      tenant = 'atlasthb';
      break;
    case '55cfbd8f-94de-4f62-a69b-6e2e83e38d33':
      tenant = 'atlasuah';
      break;
    case '9518e634-183c-452a-84cc-3a5c00b28dda':
      tenant = 'atlasuzs';
      break;
    case 'da50f5ea-5c89-411d-ac87-51cc3149023c':
      tenant = 'atlaszar';
      break;
    case 'fc5f77c5-cf79-455c-8fef-494def21ba36':
      tenant = 'pionirinternacional';
      break;
    case 'f1874e1a-40fd-4d7a-9da1-1a1b3413cdc4':
      tenant = 'sportingbookmakers';
      break;
    case '04e57bfb-8354-4165-b2f2-9362496ab79d':
      tenant = 'starsportsbetstaging';
      break;
    case '570c5c51-a2a3-459b-98aa-2ce54f4c848b':
      tenant = 'starsportsbet';
      break;
    case '28ec1e93-68f0-49fd-9ab5-639d88169625':
      tenant = 'winnerstg';
      break;
    case 'b775b423-ae27-4777-82e3-8a77a767f33c':
      tenant = 'winner';
      break;
    case 'e65926be-a608-4373-a027-869a935cbc59':
      tenant = 'worldstarbettingstaging';
      break;
    case 'e69c67b2-b7df-42ed-a6be-f18ce2654a06':
      tenant = 'worldstarbettingmwkstaging';
      break;
    case '8a63ae52-ab8c-4340-9f83-702e472a0b39':
      tenant = 'worldstarbettingzmwstaging';
      break;
    case '6da13fb2-f618-427e-ae5a-b9819cd41e68':
      tenant = 'worldstarbetting';
      break;
    case '5d1dbe2d-880f-4652-97e6-3eb997a15f34':
      tenant = 'worldstarbettingmwk';
      break;
    case '5a20971d-a9c9-4014-9c64-caab1f3e0677':
      tenant = 'worldstarbettingzmw';
      break;
    case 'bd2c8563-b83d-4b4f-84c0-018d69f012ff':
      tenant = 'worldstarbettingugx';
      break;
    case '48346282-b491-4d1e-8585-907f3148988f':
      tenant = 'worldstarbettingkes';
      break;
    case '977c301a-6620-4e69-82b6-6239c297aa72':
      tenant = 'worldstarbettinglsl';
      break;
    case ' c3c447f5-a833-479e-8b4e-b4b63d7fbef5':
      tenant = 'worldstarbettingghs';
      break;
    case '7d0539d8-4046-40d7-9bab-0e9f5a1b3a35':
      tenant = 'mdshop';
      break;
    case 'b9695a51-b8dc-4f38-a237-a1c40166de35':
      tenant = 'mdshopstaging';
      break;
    case 'dcc941c9-5dc3-4700-b12b-e33c1b4fd17c':
      tenant = 'gml';
      break;
    case 'f5554e87-4ef9-45a7-a497-308134964ee3':
      tenant = 'gmleur';
      break;
    case '17c5d5da-40f1-422f-a711-2aabd9dbc319':
      tenant = 'rocasino';
      break;
    case '300f552c-7e9d-49ff-8aab-61f1a0580616':
      tenant = 'rocasinoeur';
      break;
    case 'f79757dc-6d08-402c-bc19-a3fc8ea1e12a':
      tenant = 'fedorastaging';
      break;
    case '47baf15d-b7fd-4bf8-a929-bc95c1f1f7ec':
      tenant = 'wgbnigeria';
      break;
    case 'a089813d-75c0-4f4c-920c-8b261427806e':
      tenant = 'lonabiss';
      break;
    case 'dbff8cbd-e016-4db2-9673-9253aa725f38':
      tenant = 'betconstructmyr';
      break;
    case '36618742-8981-41dc-9c4e-07ff7ce7ea63':
      tenant = 'betconstructtnd';
      break;
    case 'a0ea5f39-65af-4501-926d-be6039535245':
      tenant = 'betconstructinr';
      break;
    case '191d878f-b76b-44f3-8c60-bff9eae604de':
      tenant = 'dream88thb';
      break;
    case 'dd29b933-2fba-45df-a04c-02c9048a7d5c':
      tenant = 'dream88usd';
      break;
    case '1d9f0292-365f-4298-9b65-cf9ac797b8d5':
      tenant = 'efbetro';
      break;
    case '2262d08f-9907-431b-a8dc-716d0d1b0521':
      tenant = 'netbetro';
      break;
    case 'ce2ed1a8-0620-4ee8-82ac-47a4461cda1f':
      tenant = 'maxbetro';
      break;
    case 'ccad4ade-a786-4429-91d3-390ae49e04da':
      tenant = 'totalbetstaging';
      break;
    case '37d3ca91-5d57-41d3-8030-a41970355b88':
      tenant = 'totalbet';
      break;
    case '315d3b3c-5faa-474d-9887-906ddbf63050':
      tenant = 'madasavastaging';
      break;
    case 'bc31a1c0-dfdc-45ba-b381-77923264fadd':
      tenant = 'gastaging';
      break;
    case '0fe41497-20ae-4f94-8870-28b4fcb11921':
      tenant = 'allbet';
      break;
    case '5be547e3-641e-4b6d-86d3-708fb88510c3':
      tenant = 'stanleybetstaging';
      break;
    case '1136f22d-84c7-4686-8836-af313f1018ee':
      tenant = 'stanleybet';
      break;
    case '087efd9b-6353-4c79-ac8b-280003fde7f9':
      tenant = 'bethouse';
      break;
    case '8de19124-b847-4c4f-a731-6d3ff64293a5':
      tenant = 'lendasbet';
      break;
    case 'f5b2fb11-f03d-42b0-956d-3d4db5d52bb4':
      tenant = 'lendasbetbrl';
      break;
    case 'bd8b7867-ea96-4b17-aa81-d288463364e0':
      tenant = 'lendasbetusd';
      break;
    case '402e1535-8b22-4ed3-9a0a-fbda27ff760b':
      tenant = 'stoiximanstaging';
      break;
    case '359e1296-1e6c-4b03-be5a-0cb733655115':
      tenant = 'stoiximan';
      break;
    case '46e16eb7-f01a-4d89-b1cc-d086371abf35':
      tenant = 'nesinestaging';
      break;
    case 'e89b63d7-4bdd-4dc2-b551-181572e357ba':
      tenant = 'mislistaging';
      break;
    case '4501402e-9f4d-4e10-b754-b8e44675dfd5':
      tenant = 'bilyonerstaging';
      break;
    case '866b5482-90f6-4760-aa25-0a19ce3c499c':
      tenant = 'pulsebetstaging';
      break;
    case 'f5221aca-8544-45d4-abe4-b68f0e728b21':
      tenant = 'winbirr';
      break;
    case 'aaba19cc-b43e-4799-b422-9ee7a9b0d315':
      tenant = 'forecastsportstrading';
      break;
    case '55223ea0-1ba3-43ba-8680-01da4f9a6c21':
      tenant = 'nesine';
      break;
    case '4dd61a16-9691-4277-9027-8cd05a647844':
      tenant = 'topbet';
      break;
    case 'ecfc3cd9-4d46-49a3-9684-bbb0f41b6999':
      tenant = 'eltribet';
      break;
    case 'e8e9000a-0c74-4997-b808-3bddd641bb63':
      tenant = 'doradobetclp';
      break;
    case '892d3cf3-a7e2-475b-baf2-492a44fe7bd1':
      tenant = 'doradobetcrc';
      break;
    case '86c01200-3187-4739-a9c4-8bec9ec3bfb0':
      tenant = 'doradobetgtq';
      break;
    case '06f8cf8e-6dcb-4dac-a5eb-e9bda6bc55eb':
      tenant = 'doradobetnio';
      break;
    case 'df211ca0-3051-4bc9-b29c-e29b620c4154':
      tenant = 'doradobetpen';
      break;
    case '35713682-a5c5-4c39-be3f-88f1bc7f3d94':
      tenant = 'doradobetusd';
      break;
    case '777d3b61-80e4-43a1-8cb1-653c052c12b0':
      tenant = 'ecuabet';
      break;
    case '987f2c24-9e28-42ea-ac74-e3b198ecda30':
      tenant = 'lotosports';
      break;
    case '4c916af2-4667-4919-8567-f83dc1bf3ea6':
      tenant = 'drewmanbetting';
      break;
    case '29f754ab-521c-4c16-b1b0-487226659797':
      tenant = 'betsportnamibia';
      break;
    case '5835ff3a-dbc5-4744-867e-d9b052396e5b':
      tenant = 'kilibet';
      break;
    case '28ec0b20-0c58-4a1d-87bc-2afe21ab5387':
      tenant = 'soccerbetbih';
      break;
    case 'b0502e35-139d-4a62-a4d9-fc7c9d2d2866':
      tenant = 'admiralbetcgonline';
      break;
    case '8305c8ff-2023-476a-957c-818ffa4ac581':
      tenant = 'admiralbetrsonline';
      break;
    case '000de10f-ccc3-4df7-a02f-2ee4ad49752c':
      tenant = 'admiralbetcg';
      break;
    case '663964d0-c920-47c0-a1ae-3947fbc32049':
      tenant = 'admiralbetrs';
      break;
    case '0335a610-8972-4003-90ab-075e5551784b':
      tenant = 'admiralbetuganda';
      break;
    case '916e1c5a-35b6-4669-83fc-ae27dc130a29':
      tenant = 'cbeteurstaging';
      break;
    case 'f29f9573-530a-423a-9f20-982d3dea9142':
      tenant = 'cbetbrlstaging';
      break;
    case '0ec637cf-b6fc-46f9-ad29-1df8c60bf913':
      tenant = 'cbetxbmstaging';
      break;
    case '49cd9210-39ed-4320-95df-349e0431b877':
      tenant = 'cbetmehstaging';
      break;
    case 'fffd7eba-dcdc-456e-befc-efa215682270':
      tenant = 'jaragamesstaging';
      break;
    case '37e9cd85-b6b8-452b-94fa-6e1a21c93cc0':
      tenant = 'jaragames';
      break;
    case 'af6d6ca9-16b4-4613-9eb6-5ae19503a01c':
      tenant = 'kilibeteur';
      break;
    case '92c1d442-bf98-4693-8ea3-5c6aa9c5f5a4':
      tenant = 'soccerbetmontenegro';
      break;
    case '5bf5bc13-a94c-42ba-9f5c-5e5380a2f58f':
      tenant = 'soccerbetserbia';
      break;
    case '3cc0ac01-402c-4f76-8524-82290729b4d3':
      tenant = 'apuestatotal';
      break;
    case 'f0beb167-38df-4e8f-bd5f-833c0d04eb87':
      tenant = 'caliente';
      break;
    case 'a349cd80-afb9-4e83-83d7-b0c2322473d4':
      tenant = 'artemisbeteur';
      break;
    case '5450bbfe-f75e-479a-9a66-f9cbc9f04572':
      tenant = 'artemisbetusd';
      break;
    case '2b873053-c439-485f-b9da-dac6ebdb2026':
      tenant = 'bolabet';
      break;
    case '669f0528-038e-4f3a-b8ca-7dcaef1d7de7':
      tenant = 'merrybet';
      break;
    case '7defd1e3-cbe8-42d5-b96e-0757f8ac90e0':
      tenant = 'betcoinsportsxbm';
      break;
    case '8858760c-0049-410c-a0d1-aad9c2520c98':
      tenant = 'betwayghs';
      break;
    case '1aa92058-a61f-4f03-aa8a-10096e43a7cc':
      tenant = 'betwaykes';
      break;
    case '5a8f55c9-1422-4968-9593-f2ba6fd8e4f0':
      tenant = 'betwaymwk';
      break;
    case '9491057e-633a-4493-84ca-99aa0c6b07a5':
      tenant = 'betwaymzn';
      break;
    case 'd265779-fd85-4fad-8ef0-9311290b042e':
      tenant = 'betwayngn';
      break;
    case 'b54a0cc0-9b1d-4f0e-8d5d-95810475e6fa':
      tenant = 'betwaytzs';
      break;
    case '255c4106-d706-418f-aab1-038153d0450c':
      tenant = 'betwayugx';
      break;
    case '8cbb9137-3ead-48d4-9b4f-0def38ea7df3':
      tenant = 'betwayzmw';
      break;
    case '3ca3c884-8f4b-42b8-a46c-219818c05937':
      tenant = 'europebet';
      break;
    case 'e8a4dafb-8221-4362-9373-ff4bcb02430d':
      tenant = 'jazzsportsdop';
      break;
    case 'd8ac7387-4ac2-4572-90a2-010917dc2236':
      tenant = 'jazzsportsusd';
      break;
    case 'e7226993-5983-4860-85ae-47cfa984eb6a':
      tenant = 'vipsportsbook';
      break;
    case 'ca13e79e-706f-4c9c-82b2-45c326957ead':
      tenant = 'vixineur';
      break;
    case '59a291bc-4641-4953-a0c7-a4f3071ce846':
      tenant = 'playbetr';
      break;
    case 'bae963f6-840b-423c-af20-d20bcabde34c':
      tenant = 'betyanga';
      break;
    case 'c6445526-62ec-45a4-957a-bb6b20431cf5':
      tenant = 'mbet';
      break;
    case '4c20b0c3-3ab0-4d69-8f46-225b8ee8ad39':
      tenant = 'mbetstaging';
      break;
    case 'bcb5be26-1937-445f-a4b2-e15d33f81203':
      tenant = 'brazilbet';
      break;
    case '23e6c9e4-0c65-4f11-9b0e-ff5362d79081':
      tenant = 'betreddice';
      break;
    case 'c56f7880-b1df-4ba6-82e4-f23c5421bdc9':
      tenant = 'starbet';
      break;
    case 'ba87327c-721d-4ba7-b4b6-ec1f5b00fbce':
      tenant = 'milibets';
      break;
    case '8370ebb8-1ef5-4ff1-9c6a-ff21039f0914':
      tenant = 'sima';
      break;
    case '84307fcd-edee-47d0-92b0-f900f7837540':
      tenant = 'slotscapital';
      break;
    case 'ba905279-6430-4912-a982-bff1773c00b2':
      tenant = 'bullcasinoeur';
      break;
    case 'cc07693d-579f-4bde-8570-f965972f233c':
      tenant = 'bullcasinogbp';
      break;
    case '4c6cc2d2-2d04-433c-b990-41bdc369eddc':
      tenant = 'bullcasinocad';
      break;
    case '5351f45a-1190-4400-af1b-b9eb7cbfb325':
      tenant = 'betmen';
      break;
    case '1d9a58ae-9a22-4ed5-b5d2-4e9eb6664240':
      tenant = 'isiparyaj';
      break;
    case '41eedf84-13e5-4e68-8e31-1e3e1a60745b':
      tenant = 'pleziparyaj';
      break;
    case '9feed51b-fd2f-4244-bc78-960fb7e9a1c2':
      tenant = 'paniplay';
      break;
    case 'f0e2604b-7647-4b7e-bf71-8f2a50a9156e':
      tenant = 'magnumbet';
      break;
    case 'e24dd07d-454b-49b0-9967-b435c27f7365':
      tenant = 'monkeytilt';
      break;
    case '317821e4-15c4-402c-af8d-a03d2d6a9c98':
      tenant = 'oktagonbet';
      break;
    case 'b9cf2c4e-11a2-4a08-8936-662604a02fae':
      tenant = 'mozzartserbia';
      break;
    case '65ce0461-7ae8-4253-8068-99437fc38853':
      tenant = 'betnest';
      break;
    default:
      break;
  }
  return tenant;
};

export default tenantByUuid;
