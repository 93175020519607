import { render, staticRenderFns } from "./TicketDetailsWrapper.vue?vue&type=template&id=c69cb9e2&scoped=true&"
import script from "./TicketDetailsWrapper.vue?vue&type=script&lang=js&"
export * from "./TicketDetailsWrapper.vue?vue&type=script&lang=js&"
import style0 from "./TicketDetailsWrapper.vue?vue&type=style&index=0&id=c69cb9e2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c69cb9e2",
  null
  
)

export default component.exports