import Gateway from '@nsoft/seven-gravity-gateway';
import eventBus from '@/utility/eventBus';

let gateway = null;

export default {
  init(store) {
    const GatewayConstructor = Gateway.slave;
    gateway = GatewayConstructor({
      debug: true,
      slaveId: 'PluginLuckySix',
      allowedOrigins: [
        'localhost:3000',
        'https://staging.seven-web.7platform.net',
        'https://staging-balkanbet.web.7platform.net',
        'https://qa.web.7platform.net',
        'https://expo.web.7platform.net',
        'https://demo.nsoft.com',
        'https://staging-bethappy.web.7platform.net',
        'https://staging-betvili.web.7platform.net',
        'https://www.future.bet',
        'https://bonusbet.live',
        'https://www.balkanbet.rs',
        'https://www.boulparyaj.web.7platform.net',
        'https://www.boulparyaj.com',
        'https://betmax888.com',
        'https://www.wett-bet.com',
        'https://staging-bugbet.web.7platform.net',
        'https://maxwin.me',
        'https://fairsplay.com',
        'https://www.sigmasupersport.com',
        'https://www.xybet.live',
        'https://staging-maxbet.web.7platform.net',
        'https://dual.maxbet.rs/ibet-web-client',
        'https://noktabet.web.7platform.net',
        'https://cashwin.web.7platform.net',
        'https://gameofbet.web.7platform.net',
        'https://circuscasino.rs',
        'https://circus.web.7platform.net',
        'https://atlasbet.web.7platform.net',
        'https://betvili.web.7platform.net',
        'https://oynabil.web.7platform.net',
        'https://staging-circus.web.7platform.net',
        'https://premiumbahis.web.7platform.net',
        'https://redfoxbet.web.7platform.net',
        'https://betasus.web.7platform.net',
        'https://staging-atlas.web.7platform.net',
        'https://kombobet.web.7platform.net',
        'https://paryerenovacash.web.7platform.net',
        'https://vbet.web.7platform.net',
        'https://staging-superbet.web.7platform.net',
        'https://www.stage.superbet.ro',
        'https://staging-wbrostage.web.7platform.net',
        'https://wbro.web.7platform.net',
        'https://mybetplus.web.7platform.net',
        'https://avalon.web.7platform.net',
        'https://qq288.web.7platform.net',
        'https://victory.web.7platform.net',
        'https://victory2.web.7platform.net',
        'https://betconstruct.web.7platform.net',
        'https://blueoceangaming.web.7platform.net',
        'https://staging-sportingbookmakers.web.7platform.net',
        'https://staging-atlas.web.7platform.net',
        'https://staging-betworld247.web.7platform.net',
        'https://betworld247.web.7platform.net',
        'https://staging-pionir.web.7platform.net',
        'https://atlas.web.7platform.net',
        'https://pionirinternacional.web.7platform.net',
        'https://sportingbookmakers.web.7platform.net',
        'https://staging-starsportsbet.web.7platform.net',
        'https://staging-winner.web.7platform.net',
        'https://winner.web.7platform.net',
        'https://staging-worldstarbetting.web.7platform.net',
        'https://starsportsbet.web.7platform.net',
        'https://mdshop.web.7platform.net',
        'https://staging-mdshop.web.7platform.net',
        'https://gml.web.7platform.net',
        'https://gmleur.web.7platform.net',
        'https://roocasino.web.7platform.net',
        'https://roocasinoeur.web.7platform.net',
        'https://staging-fedora.web.7platform.net',
        'https://wgbnigeria.web.7platform.net',
        'https://lonabiss.web.7platform.net',
        'https://dream88.web.7platform.net',
        'https://efbetro.web.7platform.net',
        'https://netbet.web.7platform.net',
        'https://maxbetro.web.7platform.net',
        'https://staging-totalbet.web.7platform.net',
        'https://staging-madasava.web.7platform.net',
        'https://staging-ga.web.7platform.net',
        'https://allbet.web.7platform.net',
        'https://www.allbetretail.com',
        'https://allbetretail.com',
        'https://stage.stb.megabet.ro',
        'https://stage.stb.megabet.ro/virtuale/18000003',
        'https://staging-stanleybetint.web.7platform.net',
        'https://www.stanleybet.ro/virtuale/18000003',
        'https://www.stanleybet.ro',
        'https://stanleybetint.web.7platform.net',
        'https://stanleybetweb.web.7platform.net',
        'https://www.superbet.ro',
        'https://superbet.web.7platform.net',
        'https://bethouse.web.7platform.net',
        'https://worldstarbetting.web.7platform.net',
        'https://lendasbet.web.7platform.net',
        'https://staging-stoiximancz.web.7platform.net',
        'https://stoiximancz.web.7platform.net',
        'https://www.betano.cz',
        'https://www.bethousenamibia.com',
        'https://staging-nesine.web.7platform.net',
        'https://staging-misli.web.7platform.net',
        'https://staging-bilyoner.web.7platform.net',
        'https://staging-pulsebet.web.7platform.net',
        'https://www.winbirr.com',
        'https://lucky6.com.na',
        'https://www.lucky6.com.na',
        'https://forecastsportstrading.web.7platform.net',
        'https://www.fst-bet.com',
        'https://nesine.web.7platform.net',
        'https://www.topbet.rs',
        'https://eltribet.com',
        'https://eltribetmxn.web.7platform.net',
        'https://doradobet.web.7platform.net',
        'https://ecuabet.web.7platform.net',
        'https://ecuabet.com',
        'https://lotosportsbrl.web.7platform.net',
        'https://lotosports.bet',
        'https://drewmanbetting.web.7platform.net',
        'https://www.oudrewmanbet.com',
        'https://www.betsportnamibia.com',
        'https://kilibet.web.7platform.net',
        'https://soccerbetbih.web.7platform.net',
        'https://www.soccerbet.ba/ba',
        'https://www.soccerbet.ba',
        'https://soccerbet.ba/ba',
        'https://soccerbet.ba',
        'https://admiralbet.me',
        'https://www.admiralbet.me',
        'https://admiralbetcgonline.web.7platform.net',
        'https://admiralbet.rs',
        'https://www.admiralbet.rs',
        'https://admiralbetrsonline.web.7platform.net',
        'https://admiralbetcg.web.7platform.net',
        'https://admiralbetrs.web.7platform.net',
        'https://admiralbetuganda.web.7platform.net',
        'https://totalbet.ro',
        'https://www.totalbet.ro',
        'https://totalbet.web.7platform.net',
        'https://staging-cbetgg.web.7platform.net',
        'https://cbet.gg/en-US',
        'https://vertbet.com/en-US',
        'https://www.cbet.gg/en-US',
        'https://www.vertbet.com/en-US',
        'https://staging-jaragamesnigeria.web.7platform.net',
        'https://jaragames.web.7platform.net',
        'https://jaragames.com',
        'https://www.jaragames.com',
        'https://www.soccerbet.me/sr_ME',
        'https://soccerbet.me/sr_ME',
        'https://soccerbetmontenegro.web.7platform.net',
        'https://www.soccerbetmontenegro.web.7platform.net',
        'https://www.soccerbet.rs/sr',
        'https://soccerbet.rs/sr',
        'https://soccerbetserbia.web.7platform.net',
        'https://www.soccerbetserbia.web.7platform.net',
        'https://apuestatotal.web.7platform.net',
        'https://caliente.web.7platform.net',
        'https://artemisbet.web.7platform.net',
        'https://bolabet.web.7platform.net',
        'https://merrybet.web.7platform.net',
        'https://betcoinsports.web.7platform.net',
        'https://betway.web.7platform.net',
        'https://europebet.web.7platform.net',
        'https://jazzsports.web.7platform.net',
        'https://vipsportsbook.web.7platform.net',
        'https://vixin.web.7platform.net',
        'https://playbetr.web.7platform.net',
        'https://betyanga.web.7platform.net',
        'https://www.betyanga.co.mw',
        'https://betyanga.co.mw',
        'https://mbet.web.7platform.net',
        'https://staging-mbet.web.7platform.net',
        'https://brazilbet.web.7platform.net',
        'https://www.brazilbet.rs',
        'https://brazilbet.rs',
        'https://betreddice.web.7platform.net',
        'https://starbet.web.7platform.net',
        'https://starbet.rs',
        'https://www.starbet.rs',
        'https://milibets.web.7platform.net',
        'https://milbets.com',
        'https://www.milbets.com',
        'https://www.simacombet.com',
        'https://simacombet.com',
        'https://simacommunication.web.7platform.net',
        'https://slotscapital.web.7platform.net',
        'https://slotscapital.bet',
        'https://www.slotscapital.bet',
        'https://bullcasino.web.7platform.net',
        'https://www.reddicesportsbet.com',
        'https://betmenweb.web.7platform.net',
        'https://www.iziparyaj.com',
        'https://iziparyaj.com',
        'https://paniplay.com',
        'https://www.paniplay.com',
        'https://paniplay.web.7platform.net',
        'https://magnumbet.ro',
        'https://monkeytilt.com',
        'https://www.monkeytilt.com',
        'https://monkeytilt.web.7platform.net',
        'https://www.oktagonbet.com',
        'https://oktagonbet.com',
        'https://oktagonbet.web.7platform.net',
        'https://www.mozzartbet.com',
        'https://mozzartbet.com',
        'https://mozzartserbia.web.7platform.net',
        'https://betnest.web.7platform.net',
      ],
      data: {
        title: 'Lucky Six v2',
      },
      eventListeners: {
        scroll: true,
      },
      load: (message) => {
        eventBus.$emit('Slave.Load', message);
        gateway.sendMessage({
          action: 'Slave.Loaded',
          data: {},
        });
      },
    });
    this.setupListeners(store);
  },
  sendMessage(action, data) {
    gateway.sendMessage({
      action,
      data,
    });
  },
  setupListeners() {
    gateway.on('User.AuthorizationChanged', (message) => {
      eventBus.$emit('User.AuthorizationChanged', message);
    });
    gateway.on('User.BalanceChanged', (message) => {
      eventBus.$emit('User.BalanceChanged', message);
    });
    gateway.on('User.ProfileChanged', (message) => {
      eventBus.$emit('User.ProfileChanged', message);
    });
    gateway.on('User.Login', () => {});
  },
};
