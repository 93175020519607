import { assign, merge } from 'lodash';
import store from '@/store';
import GameStrategy from './gameStrategy';
import api from '../api/index';

export default class SevenGameStrategy extends GameStrategy {
  constructor(config, queryParams) {
    super();
    this.config = config;
    this.params = queryParams;
    this.mandatoryParams = ['tenantUuid', 'platform', 'deliveryPlatform', 'clientplatform'];
  }

  async loadGame() {
    const sevenAppConfig = await api.getSevenAppConfig(this.config);
    this.config.product.bets = assign(sevenAppConfig.data.config.bets);
    this.config = merge(this.config, sevenAppConfig.data.config);
    await this.setSpecialsVisibility();
    await this.setSpecialBetOdds();
    await this.setPreballBetOdd();
    await this.setDrawDetails();
    store.dispatch('updateAppConfig', this.config);
    store.dispatch('updateProductRules', sevenAppConfig.data.config.rules);
    store.dispatch('updateFutureBets', sevenAppConfig.data.config.futureBets.value);

    const { token } = store.getters.player.auth;
    if (token) {
      store.dispatch('loadLastTickets');
    }
  }
}
