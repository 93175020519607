<template>
  <div class="game-header">
    <div class="settings-section">
      <i class="icon icon-arrow-left"
         v-if="showBackButton"
         @click="returnToLobby"></i>
      <i class="icon icon-info"
         v-if="showStats"
         @click="toggleStats()">
      </i>
      <i :class="[audioIcon]"
         @click="handler()"
         v-if="sound">
      </i>
      <Boost v-if="isMobile && isBoostActive"/>
    </div>
    <div class="player-info"
         v-if="showBalance">
      <div class="balance">{{balance}} {{currency}}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import isMobile from 'ismobilejs';
import eventBus from '@/utility/eventBus';
import Boost from '@/components/web/common/Boost';

export default {
  name: 'GameHeader',
  props: {
    player: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Boost,
  },
  computed: {
    ...mapGetters([
      'isAudioOn',
      'appLayout',
      'appConfig',
      'isMobile',
      'isBoostActive',
    ]),
    audioIcon() {
      return this.isAudioOn ? 'icon icon-sound-on' : 'icon icon-sound-off';
    },
    balance() {
      const { balance } = this.player;
      if (balance.balanceAmt) {
        const formatter = new Intl.NumberFormat(balance.locale, { minimumFractionDigits: 2 });
        return formatter.format(balance.balanceAmt);
      }
      return '';
    },
    currency() {
      if (this.balance) return this.appConfig.company.currency;
      return '';
    },
    sound() {
      if (isMobile().apple.device) { return false; }
      return this.appConfig.product.sound;
    },
    showStats() {
      return (this.appConfig.product.showStats && this.appConfig.company.layout === 'Compact')
        || isMobile().any;
    },
    showBackButton() {
      return this.appConfig.product.showBackButton;
    },
    showBalance() {
      return this.appConfig.company.showBalance && this.balance;
    },
  },
  methods: {
    ...mapActions([
      'toggleAudio',
      'toggleStats',
    ]),
    handler() {
      this.toggleAudio();
    },
    returnToLobby() {
      eventBus.$emit('ReturnToLobby');
    },
  },
};
</script>

<style lang="scss" scoped>
  .game-header {
    background: var(--header-bg, var(--bg-color-1));
    padding: 7px 12px;
    float: left;
    width: 100%;

    .settings-section {
      float: left;
      color: var(--text-primary-2);
      position: relative;

      .icon {
        cursor: pointer;
        margin-right: 18px;
        transition: all .5s ease;

        &:hover {
          color: var(--text-primary-1);
        }

        &:disabled, &.disabled {
          color: var(--text-primary-3);
        }
      }
    }

    .player-info {
      float: right;

      .currency {
        font-size: 10px;
        text-align: right;
        color: #efefef;
      }

      .balance {
        font-size: 12px;
        font-weight: 700;
        color: var(--accent-bg);
      }
    }
  }
</style>
