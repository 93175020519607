/* eslint no-param-reassign: ["error", { "props": false }] */

import {
  filter,
  each,
  assign,
  isArray,
} from 'lodash';
import { v4uuid } from '@nsoft/games-sdk-js/src/utility/index';
import types from './mutationTypes';

export default {
  [types.UPDATE_APP_CONFIG](state, data) {
    const { appConfig } = state;
    state.layout = data.company.layout;
    appConfig.company = assign(data.company);
    appConfig.product = assign(data.product);
    appConfig.platform.name = data.platform.toLowerCase() || 'seven';
    appConfig.platform.skinId = data.tenantId || null;
    appConfig.platform.real = data.real || null;
    appConfig.language = data.language;
    appConfig.endpoint = data.endpoint;
    appConfig.paths = data.paths;
    appConfig.ticketBlockTime = Number(data.delayTicketBlockTime.value) * 1000;
    appConfig.clientplatform = data.deliveryPlatform || data.clientplatform || data.application || 'web';
    state.platformName = data.platform;
    state.isGameLoaded = true;
    state.showFeed = data.company.showFeed;
    state.isBoostActive = data.company.boost && data.company.boost.enabled;
  },
  [types.UPDATE_PRODUCT_RULES](state, rules) {
    state.rules = assign(state.rules, rules);
    state.appConfig.company.betslip.meta.minPayment = rules.minBetAmount.value;
    state.appConfig.company.betslip.meta.totalPayment = rules.minBetAmount.value;
  },
  [types.UPDATE_FUTURE_BETS](state, value) {
    state.appConfig.company.betslip.meta.maxFutureBets = value;
  },
  [types.UPDATE_PLAYER_INFO](state, data) {
    const { player } = state;
    player.username = data.username || data.playerId || data.name;
    player.id = data.id || data.playerId;
    player.auth.token = data.secureToken || data.token;
    player.auth.tpToken = data.tpToken || null;
    player.balance.currency = data.currencyCode || state.appConfig.company.currency;
    player.timezoneOffset = data.profile ? data.profile.timezoneOffset : null;
    player.balance.currency = data.currencyCode || state.appConfig.company.currency;
    player.balance.locale = state.appConfig.company.localeCode;
    player.balance.divide = state.appConfig.company.balanceDivide;
    player.balance.balanceAmt = data.balance;
    if (data.balanceDetails) {
      player.balance.balanceType = data.balanceDetails[0].balanceType || '';
      player.balance.balanceAmt = data.balanceDetails[0].balanceAmt / player.balance.divide
        || data.balance;
    }
    player.loggedIn = !!player.id;
  },
  [types.UPDATE_PLAYER_BALANCE](state, data) {
    const { balance } = state.player;
    if (!data.balanceChangeTimestamp && data.balance) {
      balance.balanceAmt = data.balance;
    }
    if (!balance.time && data.balanceChangeTimestamp) {
      balance.time = data.balanceChangeTimestamp;
    }
    if (data.balanceChangeTimestamp >= balance.time) {
      balance.balanceAmt = data.balanceArray[0].balanceAmt / balance.divide;
    }
  },
  [types.UPDATE_PLAYER_TOKEN](state, token) {
    state.player.auth.token = token;
  },
  [types.SET_POP_ENDPOINT](state, data) {
    state.platforms[data.platform][data.env].endpoint = data.popEndpoint;
  },
  [types.SET_PALETTE](state, palette) {
    state.palette = palette;
  },
  [types.SET_LOADED](state) {
    state.isLoaded = true;
  },
  [types.TOGGLE_ACTIVE_BALLS](state, color) {
    state.colorGroup = color;
  },
  [types.CREATE_BALLS](state, totalBalls) {
    state.balls = [];
    for (let i = 1; i <= totalBalls; i += 1) {
      const ball = {};
      ball.id = i;
      ball.number = i;
      ball.active = false;
      ball.title = i;
      state.balls.push(ball);
    }
  },
  [types.UPDATE_BETS](state, payload) {
    const { betsModel } = state;
    if (!payload.outcomes.length) {
      betsModel.items = [];
    } else {
      betsModel.items.push(payload);
    }
  },
  [types.RESET_BET](state) {
    state.bet = {
      id: null,
      title: null,
      subtitle: null,
      type: null,
      odds: null,
      outcomes: [],
      stake: 1,
    };
  },
  [types.RESET_BETS](state) {
    state.betsModel.items = [];
  },
  [types.CREATE_BET](state, payload) {
    const { bet } = state;
    let sum = 0;
    let csv = '';
    bet.id = payload.id;
    bet.title = payload.outcomes;
    bet.subtitle = payload.subtitle;
    bet.type = payload.type;
    bet.odds = payload.odds;
    bet.round = state.round;
    bet.roundInfo = bet.round;
    bet.rounds = state.futureBets;
    bet.outcomes = payload.outcomes;
    bet.uuid = state.selectedBet.uuid;
    bet.multiselect = payload.multiselect;
    bet.combinations = payload.combinations ? payload.combinations : 1;
    bet.minPayment = 0;

    if (bet.combinations > 1) {
      bet.minStake = bet.combinations * state.rules.minCombBetAmount.value;
      bet.minPayment = bet.combinations * state.rules.minCombBetAmount.value;

      if (bet.minPayment < state.rules.minBetAmount.value) {
        bet.minPayment = state.rules.minBetAmount.value;
      }
    } else {
      bet.minStake = state.rules.minBetAmount.value;
      bet.minPayment = state.rules.minBetAmount.value;
    }
    bet.stake = bet.minStake;
    bet.payment = bet.minPayment;

    each(bet.outcomes, (outcome) => {
      if (bet.id === 5) {
        let k = parseInt(outcome.id, 10);
        k = (k === 7) ? 0 : k + 1;
        sum += 2 ** k;
        bet.value = sum;
      } else {
        csv += (csv.length > 0) ? `, ${String(outcome.id)}` : String(outcome.id);
        bet.value = csv;
      }
    });
  },
  [types.CREATE_RANDOM_NUMBERS](state, numbers) {
    state.randomNumbers = numbers;
  },
  [types.TOGGLE_COLORS](state) {
    state.selectAllColors = !state.selectAllColors;
  },
  [types.BETTING_ENABLED](state, enable) {
    state.bettingEnabled = enable;
  },
  [types.UPDATE_ROUND](state, round) {
    state.round = round + 1;
    state.roundInfo = state.futureBets > 1 ? `${state.round} - ${((state.round + state.futureBets) - 1)}` : state.round;
  },
  [types.DECREASE_ROUND](state) {
    state.round -= 1;
    state.roundInfo -= 1;
  },
  [types.RESET_BALLS](state) {
    state.colorGroupValue = {};
    for (let i = 0; i < state.balls.length; i += 1) {
      const ball = state.balls[i];
      ball.active = false;
    }
  },
  [types.COLORIZED_BALLS](state, color) {
    state.colorGroupValue = color;
  },
  [types.RESET_COLOR_GROUPS](state) {
    for (let i = 0; i < state.colors.length; i += 1) {
      const color = state.colors[i];
      color.active = false;
    }
  },
  [types.SELECT_ALL_COLORS](state, select) {
    state.allColorsSelected = select;
  },
  [types.RESET_SPECIAL_BETS](state) {
    each(state.specialBets, (specialBet) => {
      each(specialBet.outcomes, (value) => {
        const outcome = value;
        outcome.active = false;
      });
    });
  },
  [types.CHECK_PAYIN](state) {
    state.payinDisabled = state.player.balance.balanceAmt <= 0;
  },
  [types.SET_PRODUCT_STATS](state, statsData) {
    state.stats = {};
    state.stats = statsData;
  },
  [types.SET_PRODUCT_RESULTS](state, data) {
    state.resultsData = data;
  },
  [types.UPDATE_NOTIFICATIONS](state, payload) {
    let { message } = payload;
    let { status } = payload;
    let icon = '';

    if (payload.code && payload.code.toString().length > 0) {
      status = 'rejected';
    }

    if ((status.value && status.value.toLowerCase() === 'open') || (status === 'open')) {
      status = 'success';
    }

    if (status.value && status.value.toLowerCase() === 'closed') {
      status = 'closed';
    }

    switch (status) {
      case 'info':
        icon = 'info';
        break;
      case 'rejected':
        icon = 'void';
        break;
      case 'resolving':
        icon = 'status-b';
        message = state.translations.general_ticket_resolving;
        break;
      case 'success':
      case 'open':
        icon = 'check-a';
        message = state.translations.general_ticket_confirmed;
        break;
      case 'error':
        icon = 'void';
        message = message || state.translations.general_ticket_error;
        break;
      case 'loginFailed':
        icon = 'void';
        message = state.translations.general_ticket_login_message;
        break;
      case 'closed':
        icon = 'check-a';
        message = state.translations.general_ticket_canceled;
        break;
      default:
        message = 'Unknown notification';
        break;
    }

    if (payload.details && payload.details.fatal === 'yes') {
      state.errorOverlay = {
        message,
        active: true,
      };
    }

    const notification = payload;
    if (!notification.id) {
      notification.id = v4uuid.generate();
    }

    notification.message = message;
    notification.icon = icon;
    notification.status = status;
    state.notifications = [];
    state.notifications.push(notification);
    state.notifications = filter(state.notifications, (n) => n.id !== payload.requestUuid);
  },
  [types.HIDE_NOTIFICATION](state, notification) {
    let filteredNotifications = [];
    const id = notification.requestUuid;
    if (id) {
      filteredNotifications = filter(state.notifications, (n) => n.requestUuid !== id);
      state.notifications = filteredNotifications;
    } else {
      const idx = state.notifications.indexOf(notification);
      state.notifications.splice(idx, 1);
    }
  },
  [types.SET_TRANSLATIONS](state, translations) {
    state.translations = translations;
  },
  [types.CREATE_PREBALL](state, preball) {
    state.preball = preball;
  },
  [types.SELECT_COLOR_GROUP](state, id) {
    const colors = filter(state.colors, (color) => color.colorId === id);
    for (let i = 0; i < colors.length; i += 1) {
      const color = colors[i];
      color.active = true;
    }
  },
  [types.ALLOW_REQUEST](state, allow) {
    state.requestDisabled = allow;
  },
  [types.TOGGLE_TICKET_HISTORY](state) {
    state.expandTicketHistory = !state.expandTicketHistory;
  },
  [types.REBET](state, ticket) {
    state.ticket = ticket;
  },
  [types.CLOSE_ERROR_OVERLAY](state) {
    state.errorOverlay = {
      message: '',
      active: false,
    };
  },
  [types.LAST_TICKETS](state, data) {
    state.lastTicketsData = [];
    state.lastTicketsData = data;
  },
  [types.TICKET_HISTORY](state, data) {
    state.ticketHistoryData = data;
  },
  [types.SHOW_HIDE_TICKET_HISTORY](state) {
    state.showTicketHistory = !state.showTicketHistory;
    state.expandTicketHistory = true;
  },
  [types.SET_SPECIAL_BET_VISIBILITY](state, bets) {
    each(bets, (bet) => {
      const specialBets = filter(state.specialBets, (betItem) => betItem.id === bet.id);
      if (specialBets.length) {
        specialBets[0].visible = bet.value;
      }
    });
  },
  [types.SET_SPECIAL_BET_ODDS](state, bets) {
    each(state.specialBets, (specialBet) => {
      each(bets, (bet) => {
        if (bet.id === specialBet.id) {
          specialBet.odds = bet.odds;
          if (isArray(specialBet.odds)) {
            specialBet.odds = specialBet.odds.map((odd) => Number(odd));
          }
        }
      });
    });
  },
  [types.SET_PREBALL_BET_ODD](state, bets) {
    const { preballBet } = state;
    each(bets, (bet) => {
      if (bet.id === preballBet.id) {
        preballBet.odds = bet.odds;
      }
    });
  },
  [types.BETTING_DISABLED_EVENT](state, disabled) {
    state.bettingDisabled = disabled;
  },
  [types.LOAD_PLUGINS](state, plugins) {
    state.plugins = plugins;
  },
  [types.TOGGLE_AUDIO](state) {
    state.player.audio = !state.player.audio;
  },
  [types.SET_DRAWN_BALLS](state, payload) {
    if (isArray(payload)) {
      state.drawnBalls = payload;
    } else {
      state.drawnBalls.push(payload);
    }

    each(state.lastTicketsData, (ticket) => {
      if (ticket.status.value === 'IN_PLAY' || ticket.status.value === 'OPEN') {
        each(ticket.bets, (betItem) => {
          if (betItem.eventId === state.round - 1) {
            const bet = betItem;
            bet.eventValue = '';
            each(state.drawnBalls, (drawn) => {
              bet.eventValue += `${drawn.ball},`;
            });
          }
        });
      }
    });
  },
  [types.TOGGLE_STATS](state) {
    state.showModalStats = !state.showModalStats;
  },
  [types.QUICK_PAYIN](state, bet) {
    state.ticket.bets = [];
    state.ticket.bets.push(bet);
  },
  [types.LOCK_BETTING](state, locked) {
    state.bettingLocked = locked;
  },
  [types.LAST_TICKETS_IN_PROGRESS](state, inProgress) {
    state.lastTicketsInProgress = inProgress;
  },
  [types.TICKET_HISTORY_IN_PROGRESS](state, inProgress) {
    state.ticketHistoryInProgress = inProgress;
  },
  [types.SET_TICKET_HISTORY_RANGE_ERROR](state, errorData) {
    state.ticketHistoryRangeError = errorData;
  },
  [types.SET_FULL_SCREEN_STATUS](state, fullScreen) {
    state.isFullScreen = fullScreen;
  },
  [types.SET_LOCALIZED_TRANSLATIONS](state, translations) {
    state.localizedTranslations = translations;
  },
  [types.TOGGLE_NEW_FEATURES](state) {
    state.showNewFeatures = !state.showNewFeatures;
  },
  [types.SET_THIRD_PARTY_TOKEN](state, token) {
    state.thirdPartyToken = token;
  },
  [types.SET_IS_MOBILE](state, isMobile) {
    state.isMobile = isMobile;
  },
};
