export default {
  prvafirma: () => import(/* webpackChunkName: "prvafirma" */'./prvafirma'),
  efortunastgro: () => import(/* webpackChunkName: "efortunastgro" */'./efortunastgro'),
  efortuna: () => import(/* webpackChunkName: "efortuna" */'./efortuna'),
  ifortunastgcz: () => import(/* webpackChunkName: "ifortunastgcz" */'./ifortunastgcz'),
  bifortunacz: () => import(/* webpackChunkName: "bifortunacz" */'./bifortunacz'),
  casapariurilorstg: () => import(/* webpackChunkName: "casapariurilorstg" */'./casapariurilorstg'),
  casapariurilor: () => import(/* webpackChunkName: "casapariurilor" */'./casapariurilor'),
  balkanbetstaging: () => import(/* webpackChunkName: "balkanbetstaging" */'./balkanbetstaging'),
  playtech850036: () => import(/* webpackChunkName: "playtech850036" */'./playtech850036'),
  playtech850037: () => import(/* webpackChunkName: "playtech850037" */'./playtech850037'),
  qa: () => import(/* webpackChunkName: "qa" */ './qa'),
  ifortunabuildcz: () => import(/* webpackChunkName: "ifortunabuildcz" */'./ifortunabuildcz'),
  efortunabuildro: () => import(/* webpackChunkName: "efortunabuildro" */'./efortunabuildro'),
  casapariurilorbuild: () => import(/* webpackChunkName: "casapariurilorbuild" */'./casapariurilorbuild'),
  expo: () => import(/* webpackChunkName: "expo" */'./expo'),
  demo: () => import(/* webpackChunkName: "demo" */'./demo'),
  betvilistaging: () => import(/* webpackChunkName: "betvilistaging" */'./betvilistaging'),
  futurebet: () => import(/* webpackChunkName: "futurebet" */'./futurebet'),
  bethappyeurstaging: () => import(/* webpackChunkName: "bethappyeurstaging" */'./bethappyeurstaging'),
  bethappyusdstaging: () => import(/* webpackChunkName: "bethappyusdstaging" */'./bethappyusdstaging'),
  bethappytrystaging: () => import(/* webpackChunkName: "bethappytrystaging" */'./bethappytrystaging'),
  bmk: () => import(/* webpackChunkName: "bmk" */'./bmk'),
  balkanbet: () => import(/* webpackChunkName: "balkanbet" */'./balkanbet'),
  boulparyaj: () => import(/* webpackChunkName: "boulparyaj" */'./boulparyaj'),
  admiral2: () => import(/* webpackChunkName: "admiral2" */'./admiral2'),
  wettnet: () => import(/* webpackChunkName: "wettnet" */'./wettnet'),
  bugbetstaging: () => import(/* webpackChunkName: "bugbetstaging" */'./bugbetstaging'),
  piramida: () => import(/* webpackChunkName: "piramida" */'./piramida'),
  fairplay: () => import(/* webpackChunkName: "fairplay" */'./fairplay'),
  sigma: () => import(/* webpackChunkName: "sigma" */'./sigma'),
  maxbetsrbstaging: () => import(/* webpackChunkName: "maxbetsrbstaging" */'./maxbetsrbstaging'),
  noktabet: () => import(/* webpackChunkName: "noktabet" */'./noktabet'),
  cashwin: () => import(/* webpackChunkName: "cashwin" */'./cashwin'),
  gameofbet: () => import(/* webpackChunkName: "gameofbet" */'./gameofbet'),
  circus: () => import(/* webpackChunkName: "circus" */'./circus'),
  atlasbet: () => import(/* webpackChunkName: "atlasbet" */'./atlasbet'),
  betviliproduction: () => import(/* webpackChunkName: "betviliproduction" */'./betviliproduction'),
  oynabil: () => import(/* webpackChunkName: "oynabil" */'./oynabil'),
  betvilixmbproduction: () => import(/* webpackChunkName: "betvilixmbproduction" */'./betvilixmbproduction'),
  betvilixmbstaging: () => import(/* webpackChunkName: "betvilixmbstaging" */'./betvilixmbstaging'),
  circusstaging: () => import(/* webpackChunkName: "circusstaging" */'./circusstaging'),
  premiumbahis: () => import(/* webpackChunkName: "premiumbahis" */'./premiumbahis'),
  premiumbahistry: () => import(/* webpackChunkName: "premiumbahistry" */'./premiumbahistry'),
  premiumbahisusd: () => import(/* webpackChunkName: "premiumbahisusd" */'./premiumbahisusd'),
  redfoxbet: () => import(/* webpackChunkName: "redfoxbet" */'./redfoxbet'),
  betasus: () => import(/* webpackChunkName: "betasus" */'./betasus'),
  atlasstaging: () => import(/* webpackChunkName: "atlasstaging" */'./atlasstaging'),
  kombobet: () => import(/* webpackChunkName: "kombobet" */'./kombobet'),
  paryerenovacash: () => import(/* webpackChunkName: "paryerenovacash" */'./paryerenovacash'),
  vbeteurope: () => import(/* webpackChunkName: "vbeteur" */'./vbeteur'),
  vbetbrl: () => import(/* webpackChunkName: "vbetbrl" */'./vbetbrl'),
  vbetcad: () => import(/* webpackChunkName: "vbetcad" */'./vbetcad'),
  vbetcny: () => import(/* webpackChunkName: "vbetcny" */'./vbetcny'),
  vbeteur: () => import(/* webpackChunkName: "vbeteur" */'./vbeteur'),
  vbetgel: () => import(/* webpackChunkName: "vbetgel" */'./vbetgel'),
  vbetkzt: () => import(/* webpackChunkName: "vbetkzt" */'./vbetkzt'),
  vbetpln: () => import(/* webpackChunkName: "vbetpln" */'./vbetpln'),
  vbetrub: () => import(/* webpackChunkName: "vbetrub" */'./vbetrub'),
  vbetsek: () => import(/* webpackChunkName: "vbetsek" */'./vbetsek'),
  vbettry: () => import(/* webpackChunkName: "vbettry" */'./vbettry'),
  vbetusd: () => import(/* webpackChunkName: "vbetusd" */'./vbetusd'),
  superbetstaging: () => import(/* webpackChunkName: "superbetstaging" */'./superbetstaging'),
  superbet: () => import(/* webpackChunkName: "superbet" */'./superbet'),
  wbrostaging: () => import(/* webpackChunkName: "wbrostaging" */'./wbrostaging'),
  wbro: () => import(/* webpackChunkName: "wbro" */'./wbro'),
  mybetplus: () => import(/* webpackChunkName: "mybetplus" */'./mybetplus'),
  avaloneur: () => import(/* webpackChunkName: "avaloneur" */'./avaloneur'),
  avalonusd: () => import(/* webpackChunkName: "avalonusd" */'./avalonusd'),
  avalonrub: () => import(/* webpackChunkName: "avalonrub" */'./avalonrub'),
  avalonbyn: () => import(/* webpackChunkName: "avalonbyn" */'./avalonbyn'),
  victoryeur: () => import(/* webpackChunkName: "victoryeur" */'./victoryeur'),
  victoryusd: () => import(/* webpackChunkName: "victoryusd" */'./victoryusd'),
  victoryngn: () => import(/* webpackChunkName: "victoryngn" */'./victoryngn'),
  victory2eur: () => import(/* webpackChunkName: "victory2eur" */'./victory2eur'),
  victory2usd: () => import(/* webpackChunkName: "victory2usd" */'./victory2usd'),
  victory2brl: () => import(/* webpackChunkName: "victory2brl" */'./victory2brl'),
  qq288usd: () => import(/* webpackChunkName: "qq288usd" */'./qq288usd'),
  qq288cny: () => import(/* webpackChunkName: "qq288cny" */'./qq288cny'),
  qq288idr: () => import(/* webpackChunkName: "qq288idr" */'./qq288idr'),
  qq288krw: () => import(/* webpackChunkName: "qq288krw" */'./qq288krw'),
  qq288myr: () => import(/* webpackChunkName: "qq288myr" */'./qq288myr'),
  qq288thb: () => import(/* webpackChunkName: "qq288thb" */'./qq288thb'),
  qq288vnd: () => import(/* webpackChunkName: "qq288vnd" */'./qq288vnd'),
  betconstruct: () => import(/* webpackChunkName: "betconstruct" */'./betconstruct'),
  betconstructeur: () => import(/* webpackChunkName: "betconstructeur" */'./betconstructeur'),
  betconstructazn: () => import(/* webpackChunkName: "betconstructazn" */'./betconstructazn'),
  betconstructbrl: () => import(/* webpackChunkName: "betconstructbrl" */'./betconstructbrl'),
  betconstructcad: () => import(/* webpackChunkName: "betconstructcad" */'./betconstructcad'),
  betconstructcny: () => import(/* webpackChunkName: "betconstructcny" */'./betconstructcny'),
  betconstructgbp: () => import(/* webpackChunkName: "betconstructgbp" */'./betconstructgbp'),
  betconstructgel: () => import(/* webpackChunkName: "betconstructgel" */'./betconstructgel'),
  betconstructirr: () => import(/* webpackChunkName: "betconstructirr" */'./betconstructirr'),
  betconstructkrw: () => import(/* webpackChunkName: "betconstructkrw" */'./betconstructkrw'),
  betconstructkzt: () => import(/* webpackChunkName: "betconstructkzt" */'./betconstructkzt'),
  betconstructpln: () => import(/* webpackChunkName: "betconstructpln" */'./betconstructpln'),
  betconstructrub: () => import(/* webpackChunkName: "betconstructrub" */'./betconstructrub'),
  betconstructsek: () => import(/* webpackChunkName: "betconstructsek" */'./betconstructsek'),
  betconstructtry: () => import(/* webpackChunkName: "betconstructtry" */'./betconstructtry'),
  betconstructuah: () => import(/* webpackChunkName: "betconstructuah" */'./betconstructuah'),
  betconstructusd: () => import(/* webpackChunkName: "betconstructusd" */'./betconstructusd'),
  betconstructmyr: () => import(/* webpackChunkName: "betconstructmyr" */'./betconstructmyr'),
  betconstructtnd: () => import(/* webpackChunkName: "betconstructtnd" */'./betconstructtnd'),
  betconstructinr: () => import(/* webpackChunkName: "betconstructinr" */'./betconstructinr'),
  betconstructamd: () => import(/* webpackChunkName: "betconstructamd" */'./betconstructamd'),
  betconstructbyn: () => import(/* webpackChunkName: "betconstructbyn" */'./betconstructbyn'),
  betconstructuzs: () => import(/* webpackChunkName: "betconstructuzs" */'./betconstructuzs'),
  betconstructmad: () => import(/* webpackChunkName: "betconstructmad" */'./betconstructmad'),
  betconstructnad: () => import(/* webpackChunkName: "betconstructnad" */'./betconstructnad'),
  blueoceangaming: () => import(/* webpackChunkName: "blueoceangaming" */'./blueoceangaming'),
  blueoceangamingusd: () => import(/* webpackChunkName: "blueoceangamingusd" */'./blueoceangamingusd'),
  blueoceangamingall: () => import(/* webpackChunkName: "blueoceangamingall" */'./blueoceangamingall'),
  blueoceangamingars: () => import(/* webpackChunkName: "blueoceangamingars" */'./blueoceangamingars'),
  blueoceangamingaud: () => import(/* webpackChunkName: "blueoceangamingaud" */'./blueoceangamingaud'),
  blueoceangamingazn: () => import(/* webpackChunkName: "blueoceangamingazn" */'./blueoceangamingazn'),
  blueoceangamingcad: () => import(/* webpackChunkName: "blueoceangamingcad" */'./blueoceangamingcad'),
  blueoceangamingchf: () => import(/* webpackChunkName: "blueoceangamingchf" */'./blueoceangamingchf'),
  blueoceangamingeur: () => import(/* webpackChunkName: "blueoceangamingeur" */'./blueoceangamingeur'),
  blueoceangaminggbp: () => import(/* webpackChunkName: "blueoceangaminggbp" */'./blueoceangaminggbp'),
  blueoceangamingnok: () => import(/* webpackChunkName: "blueoceangamingnok" */'./blueoceangamingnok'),
  blueoceangamingnzd: () => import(/* webpackChunkName: "blueoceangamingnzd" */'./blueoceangamingnzd'),
  blueoceangamingpen: () => import(/* webpackChunkName: "blueoceangamingpen" */'./blueoceangamingpen'),
  blueoceangamingphp: () => import(/* webpackChunkName: "blueoceangamingphp" */'./blueoceangamingphp'),
  blueoceangamingpln: () => import(/* webpackChunkName: "blueoceangamingpln" */'./blueoceangamingpln'),
  blueoceangamingrsd: () => import(/* webpackChunkName: "blueoceangamingrsd" */'./blueoceangamingrsd'),
  blueoceangamingrub: () => import(/* webpackChunkName: "blueoceangamingrub" */'./blueoceangamingrub'),
  blueoceangamingtnd: () => import(/* webpackChunkName: "blueoceangamingtnd" */'./blueoceangamingtnd'),
  blueoceangamingtry: () => import(/* webpackChunkName: "blueoceangamingtry" */'./blueoceangamingtry'),
  blueoceangamingves: () => import(/* webpackChunkName: "blueoceangamingves" */'./blueoceangamingves'),
  blueoceangamingxmb: () => import(/* webpackChunkName: "blueoceangamingxmb" */'./blueoceangamingxmb'),
  blueoceangamingbam: () => import(/* webpackChunkName: "blueoceangamingbam" */'./blueoceangamingbam'),
  blueoceangamingttd: () => import(/* webpackChunkName: "blueoceangamingttd" */'./blueoceangamingttd'),
  blueoceangaminghkd: () => import(/* webpackChunkName: "blueoceangaminghkd" */'./blueoceangaminghkd'),
  sportingbookmakersstaging: () => import(/* webpackChunkName: "sportingbookmakersstaging" */'./sportingbookmakersstaging'),
  atlasusdstaging: () => import(/* webpackChunkName: "atlasusdstaging" */'./atlasusdstaging'),
  betworld247staging: () => import(/* webpackChunkName: "betworld247staging" */'./betworld247staging'),
  betworld247: () => import(/* webpackChunkName: "betworld247" */'./betworld247'),
  pionirstaging: () => import(/* webpackChunkName: "pionirstaging" */'./pionirstaging'),
  atlas: () => import(/* webpackChunkName: "atlas" */'./atlas'),
  atlasusd: () => import(/* webpackChunkName: "atlasusd" */'./atlasusd'),
  atlasbrl: () => import(/* webpackChunkName: "atlasbrl" */'./atlasbrl'),
  atlascad: () => import(/* webpackChunkName: "atlascad" */'./atlascad'),
  atlasclp: () => import(/* webpackChunkName: "atlasclp" */'./atlasclp'),
  atlaseur: () => import(/* webpackChunkName: "atlaseur" */'./atlaseur'),
  atlasgbp: () => import(/* webpackChunkName: "atlasgbp" */'./atlasgbp'),
  atlasinr: () => import(/* webpackChunkName: "atlasinr" */'./atlasinr'),
  atlasjpy: () => import(/* webpackChunkName: "atlasjpy" */'./atlasjpy'),
  atlaskzt: () => import(/* webpackChunkName: "atlaskzt" */'./atlaskzt'),
  atlasmxn: () => import(/* webpackChunkName: "atlasmxn" */'./atlasmxn'),
  atlaspen: () => import(/* webpackChunkName: "atlaspen" */'./atlaspen'),
  atlasrub: () => import(/* webpackChunkName: "atlasrub" */'./atlasrub'),
  atlasthb: () => import(/* webpackChunkName: "atlasthb" */'./atlasthb'),
  atlasuah: () => import(/* webpackChunkName: "atlasuah" */'./atlasuah'),
  atlasuzs: () => import(/* webpackChunkName: "atlasuzs" */'./atlasuzs'),
  atlaszar: () => import(/* webpackChunkName: "atlaszar" */'./atlaszar'),
  pionirinternacional: () => import(/* webpackChunkName: "pionirinternacional" */'./pionirinternacional'),
  sportingbookmakers: () => import(/* webpackChunkName: "sportingbookmakers" */'./sportingbookmakers'),
  starsportsbetstaging: () => import(/* webpackChunkName: "starsportsbetstaging" */'./starsportsbetstaging'),
  winnerstg: () => import(/* webpackChunkName: "winnerstg" */'./winnerstg'),
  winner: () => import(/* webpackChunkName: "winner" */'./winner'),
  worldstarbettingstaging: () => import(/* webpackChunkName: "worldstarbettingstaging" */'./worldstarbettingstaging'),
  worldstarbettingmwkstaging: () => import(/* webpackChunkName: "worldstarbettingmwkstaging" */'./worldstarbettingmwkstaging'),
  worldstarbettingzmwstaging: () => import(/* webpackChunkName: "worldstarbettingzmwstaging" */'./worldstarbettingzmwstaging'),
  worldstarbetting: () => import(/* webpackChunkName: "worldstarbetting" */'./worldstarbetting'),
  worldstarbettingmwk: () => import(/* webpackChunkName: "worldstarbettingmwk" */'./worldstarbettingmwk'),
  worldstarbettingzmw: () => import(/* webpackChunkName: "worldstarbettingzmw" */'./worldstarbettingzmw'),
  worldstarbettingugx: () => import(/* webpackChunkName: "worldstarbettingugx" */'./worldstarbettingugx'),
  worldstarbettingkes: () => import(/* webpackChunkName: "worldstarbettingkes" */'./worldstarbettingkes'),
  worldstarbettinglsl: () => import(/* webpackChunkName: "worldstarbettinglsl" */'./worldstarbettinglsl'),
  worldstarbettingghs: () => import(/* webpackChunkName: "worldstarbettingghs" */'./worldstarbettingghs'),
  starsportsbet: () => import(/* webpackChunkName: "starsportsbet" */'./starsportsbet'),
  mdshop: () => import(/* webpackChunkName: "mdshop" */'./mdshop'),
  mdshopstaging: () => import(/* webpackChunkName: "mdshopstaging" */'./mdshopstaging'),
  gml: () => import(/* webpackChunkName: "gml" */'./gml'),
  gmleur: () => import(/* webpackChunkName: "gmleur" */'./gmleur'),
  rocasino: () => import(/* webpackChunkName: "rocasino" */'./rocasino'),
  rocasinoeur: () => import(/* webpackChunkName: "rocasinoeur" */'./rocasinoeur'),
  fedorastaging: () => import(/* webpackChunkName: "fedorastaging" */'./fedorastaging'),
  wgbnigeria: () => import(/* webpackChunkName: "wgbnigeria" */'./wgbnigeria'),
  lonabiss: () => import(/* webpackChunkName: "lonabiss" */'./lonabiss'),
  dream88thb: () => import(/* webpackChunkName: "dream88thb" */'./dream88thb'),
  dream88usd: () => import(/* webpackChunkName: "dream88usd" */'./dream88usd'),
  efbetro: () => import(/* webpackChunkName: "efbetro" */'./efbetro'),
  netbetro: () => import(/* webpackChunkName: "netbetro" */'./netbetro'),
  maxbetro: () => import(/* webpackChunkName: "maxbetro" */'./maxbetro'),
  totalbetstaging: () => import(/* webpackChunkName: "totalbetstaging" */'./totalbetstaging'),
  madasavastaging: () => import(/* webpackChunkName: "madasavastaging" */'./madasavastaging'),
  gastaging: () => import(/* webpackChunkName: "gastaging" */'./gastaging'),
  allbet: () => import(/* webpackChunkName: "allbet" */'./allbet'),
  stanleybetstaging: () => import(/* webpackChunkName: "stanleybetstaging" */'./stanleybetstaging'),
  stanleybet: () => import(/* webpackChunkName: "stanleybet" */'./stanleybet'),
  bethouse: () => import(/* webpackChunkName: "bethouse" */'./bethouse'),
  lendasbet: () => import(/* webpackChunkName: "lendasbet" */'./lendasbet'),
  lendasbetbrl: () => import(/* webpackChunkName: "lendasbetbrl" */'./lendasbetbrl'),
  lendasbetusd: () => import(/* webpackChunkName: "lendasbetusd" */'./lendasbetusd'),
  stoiximanstaging: () => import(/* webpackChunkName: "stoiximanstaging" */'./stoiximanstaging'),
  stoiximan: () => import(/* webpackChunkName: "stoiximan" */'./stoiximan'),
  nesinestaging: () => import(/* webpackChunkName: "nesinestaging" */'./nesinestaging'),
  mislistaging: () => import(/* webpackChunkName: "mislistaging" */'./mislistaging'),
  bilyonerstaging: () => import(/* webpackChunkName: "bilyonerstaging" */'./bilyonerstaging'),
  pulsebetstaging: () => import(/* webpackChunkName: "pulsebetstaging" */'./pulsebetstaging'),
  winbirr: () => import(/* webpackChunkName: "winbirr" */'./winbirr'),
  forecastsportstrading: () => import(/* webpackChunkName: "forecastsportstrading" */'./forecastsportstrading'),
  nesine: () => import(/* webpackChunkName: "nesine" */'./nesine'),
  topbet: () => import(/* webpackChunkName: "topbet" */'./topbet'),
  eltribet: () => import(/* webpackChunkName: "eltribet" */'./eltribet'),
  doradobetclp: () => import(/* webpackChunkName: "doradobetclp" */'./doradobetclp'),
  doradobetcrc: () => import(/* webpackChunkName: "doradobetcrc" */'./doradobetcrc'),
  doradobetgtq: () => import(/* webpackChunkName: "doradobetgtq" */'./doradobetgtq'),
  doradobetnio: () => import(/* webpackChunkName: "doradobetnio" */'./doradobetnio'),
  doradobetpen: () => import(/* webpackChunkName: "doradobetpen" */'./doradobetpen'),
  doradobetusd: () => import(/* webpackChunkName: "doradobetusd" */'./doradobetusd'),
  ecuabet: () => import(/* webpackChunkName: "ecuabet" */'./ecuabet'),
  lotosports: () => import(/* webpackChunkName: "lotosports" */'./lotosports'),
  drewmanbetting: () => import(/* webpackChunkName: "drewmanbetting" */'./drewmanbetting'),
  betsportnamibia: () => import(/* webpackChunkName: "betsportnamibia" */'./betsportnamibia'),
  kilibet: () => import(/* webpackChunkName: "kilibet" */'./kilibet'),
  kilibeteur: () => import(/* webpackChunkName: "kilibeteur" */'./kilibeteur'),
  soccerbetbih: () => import(/* webpackChunkName: "soccerbetbih" */'./soccerbetbih'),
  admiralbetcgonline: () => import(/* webpackChunkName: "admiralbetcgonline" */'./admiralbetcgonline'),
  admiralbetrsonline: () => import(/* webpackChunkName: "admiralbetrsonline" */'./admiralbetrsonline'),
  admiralbetcg: () => import(/* webpackChunkName: "admiralbetcg" */'./admiralbetcg'),
  admiralbetrs: () => import(/* webpackChunkName: "admiralbetrs" */'./admiralbetrs'),
  admiralbetuganda: () => import(/* webpackChunkName: "admiralbetuganda" */'./admiralbetuganda'),
  totalbet: () => import(/* webpackChunkName: "totalbet" */'./totalbet'),
  cbeteurstaging: () => import(/* webpackChunkName: "cbeteurstaging" */'./cbeteurstaging'),
  cbetbrlstaging: () => import(/* webpackChunkName: "cbetbrlstaging" */'./cbetbrlstaging'),
  cbetxbmstaging: () => import(/* webpackChunkName: "cbetxbmstaging" */'./cbetxbmstaging'),
  cbetmehstaging: () => import(/* webpackChunkName: "cbetmehstaging" */'./cbetmehstaging'),
  jaragamesstaging: () => import(/* webpackChunkName: "jaragamesstaging" */'./jaragamesstaging'),
  jaragames: () => import(/* webpackChunkName: "jaragames" */'./jaragames'),
  soccerbetmontenegro: () => import(/* webpackChunkName: "soccerbetmontenegro" */'./soccerbetmontenegro'),
  soccerbetserbia: () => import(/* webpackChunkName: "soccerbetserbia" */'./soccerbetserbia'),
  apuestatotal: () => import(/* webpackChunkName: "apuestatotal" */'./apuestatotal'),
  caliente: () => import(/* webpackChunkName: "caliente" */'./caliente'),
  artemisbeteur: () => import(/* webpackChunkName: "artemisbeteur" */'./artemisbeteur'),
  artemisbetusd: () => import(/* webpackChunkName: "artemisbetusd" */'./artemisbetusd'),
  bolabet: () => import(/* webpackChunkName: "bolabet" */'./bolabet'),
  merrybet: () => import(/* webpackChunkName: "merrybet" */'./merrybet'),
  betcoinsportsxbm: () => import(/* webpackChunkName: "betcoinsportsxbm" */'./betcoinsportsxbm'),
  betwayghs: () => import(/* webpackChunkName: "betwayghs" */'./betwayghs'),
  betwaykes: () => import(/* webpackChunkName: "betwaykes" */'./betwaykes'),
  betwaymwk: () => import(/* webpackChunkName: "betwaymwk" */'./betwaymwk'),
  betwaymzn: () => import(/* webpackChunkName: "betwaymzn" */'./betwaymzn'),
  betwayngn: () => import(/* webpackChunkName: "betwayngn" */'./betwayngn'),
  betwaytzs: () => import(/* webpackChunkName: "betwaytzs" */'./betwaytzs'),
  betwayugx: () => import(/* webpackChunkName: "betwayugx" */'./betwayugx'),
  betwayzmw: () => import(/* webpackChunkName: "betwayzmw" */'./betwayzmw'),
  europebet: () => import(/* webpackChunkName: "europebet" */'./europebet'),
  jazzsportsdop: () => import(/* webpackChunkName: "jazzsportsdop" */'./jazzsportsdop'),
  jazzsportsusd: () => import(/* webpackChunkName: "jazzsportsusd" */'./jazzsportsusd'),
  vipsportsbook: () => import(/* webpackChunkName: "vipsportsbook" */'./vipsportsbook'),
  vixineur: () => import(/* webpackChunkName: "vixineur" */'./vixineur'),
  playbetr: () => import(/* webpackChunkName: "playbetr" */'./playbetr'),
  betyanga: () => import(/* webpackChunkName: "betyanga" */'./betyanga'),
  mbet: () => import(/* webpackChunkName: "mbet" */'./mbet'),
  mbetstaging: () => import(/* webpackChunkName: "mbetstaging" */'./mbetstaging'),
  brazilbet: () => import(/* webpackChunkName: "brazilbet" */'./brazilbet'),
  betreddice: () => import(/* webpackChunkName: "betreddice" */'./betreddice'),
  starbet: () => import(/* webpackChunkName: "starbet" */'./starbet'),
  milibets: () => import(/* webpackChunkName: "milibets" */'./milibets'),
  sima: () => import(/* webpackChunkName: "sima" */'./sima'),
  slotscapital: () => import(/* webpackChunkName: "slotscapital" */'./slotscapital'),
  bullcasinoeur: () => import(/* webpackChunkName: "bullcasinoeur" */'./bullcasinoeur'),
  bullcasinogpb: () => import(/* webpackChunkName: "bullcasinogpb" */'./bullcasinogpb'),
  bullcasinocad: () => import(/* webpackChunkName: "bullcasinocad" */'./bullcasinocad'),
  betmen: () => import(/* webpackChunkName: "betmen" */'./betmen'),
  isiparyaj: () => import(/* webpackChunkName: "isiparyaj" */'./isiparyaj'),
  pleziparyaj: () => import(/* webpackChunkName: "pleziparyaj" */'./pleziparyaj'),
  paniplay: () => import(/* webpackChunkName: "paniplay" */'./paniplay'),
  magnumbet: () => import(/* webpackChunkName: "magnumbet" */'./magnumbet'),
  monkeytilt: () => import(/* webpackChunkName: "monkeytilt" */'./monkeytilt'),
  oktagonbet: () => import(/* webpackChunkName: "oktagonbet" */'./oktagonbet'),
  mozzartserbia: () => import(/* webpackChunkName: "mozzartserbia" */'./mozzartserbia'),
  betnest: () => import(/* webpackChunkName: "betnest" */'./betnest'),
};
