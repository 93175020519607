import each from 'lodash/each';
import assign from 'lodash/assign';
import mutationTypes from '@/store/mutationTypes';
import api from '@/api';
import { serverEnv } from '../../config/index';

const resolveColors = (value) => {
  const resolved = [];
  let colorIndex = '';
  each([0, 1, 2, 3, 4, 5, 6, 7, 8], (n) => {
    if (parseInt(value, 10) & 2 ** n) {
      colorIndex = (n === 0) ? 7 : n - 1;
      resolved.push(String(colorIndex));
    }
  });
  return resolved;
};

const formatTitle = (bet, colors) => {
  const title = [];
  each(colors, (key) => {
    title.push(bet.outcomes[key].title);
  });

  return title;
};

let notificationTimer = null;
let check = null;
const checkTimeout = 10000;

export default {
  updateAppConfig({ commit }, data) {
    commit(mutationTypes.UPDATE_APP_CONFIG, data);
  },
  updateProductRules({ commit }, rules) {
    commit(mutationTypes.UPDATE_PRODUCT_RULES, rules);
  },
  updateFutureBets({ commit }, value) {
    commit(mutationTypes.UPDATE_FUTURE_BETS, value);
  },
  registerClientApp({ commit }, app) {
    commit(mutationTypes.REGISTER_CLIENT_APP, app);
  },
  createBalls({ commit }, totalBalls) {
    commit(mutationTypes.CREATE_BALLS, totalBalls);
  },
  updateBets({ commit }, bet) {
    commit(mutationTypes.UPDATE_BETS, bet);
  },
  resetBet({ commit }) {
    commit(mutationTypes.RESET_BET);
  },
  resetBets({ commit, dispatch }) {
    commit(mutationTypes.RESET_BETS);
    dispatch('resetBet');
  },
  createBet({ commit, dispatch }, payload) {
    if (payload.id === 10) {
      commit(mutationTypes.CREATE_BET, payload);
      return;
    }

    if (payload.type === 'Standard') {
      dispatch('resetSpecialBets');
    } else {
      dispatch('resetGridSelection');
    }
    commit(mutationTypes.CREATE_BET, payload);
    dispatch('checkPayin');
  },
  selectColorGroup({ commit }, colorId) {
    commit(mutationTypes.SELECT_COLOR_GROUP, colorId);
  },
  generateRandomNumbers({ commit }, numbers) {
    commit(mutationTypes.CREATE_RANDOM_NUMBERS, numbers);
  },
  toggleColors({ commit }) {
    commit(mutationTypes.TOGGLE_COLORS);
  },
  setPalette({ commit }, palette) {
    commit(mutationTypes.SET_PALETTE, palette);
  },
  enableBetting({ commit }, enable) {
    commit(mutationTypes.BETTING_ENABLED, enable);
  },
  updatePlayerInfo({ commit }, data) {
    commit(mutationTypes.UPDATE_PLAYER_INFO, data);
  },
  updatePlayerBalance({ commit }, data) {
    commit(mutationTypes.UPDATE_PLAYER_BALANCE, data);
  },
  updatePlayerToken({ commit }, token) {
    commit(mutationTypes.UPDATE_PLAYER_TOKEN, token);
  },
  setPopEndpoint({ commit }, data) {
    commit(mutationTypes.SET_POP_ENDPOINT, data);
  },
  updateRound({ commit }, round) {
    commit(mutationTypes.UPDATE_ROUND, round);
  },
  decreaseRound({ commit }) {
    commit(mutationTypes.DECREASE_ROUND);
  },
  resetBalls({ commit }) {
    commit(mutationTypes.RESET_BALLS);
  },
  colorizedBalls({ commit }, color) {
    commit(mutationTypes.COLORIZED_BALLS, color);
  },
  resetColorGroups({ commit }) {
    commit(mutationTypes.RESET_COLOR_GROUPS);
  },
  selectAllColors({ commit }, select) {
    commit(mutationTypes.SELECT_ALL_COLORS, select);
  },
  resetGridSelection({ dispatch }) {
    dispatch('resetColorGroups');
    dispatch('resetBalls');
  },
  resetSpecialBets({ commit }) {
    commit(mutationTypes.RESET_SPECIAL_BETS);
  },
  resetSelection({ dispatch }) {
    dispatch('resetGridSelection');
    dispatch('resetSpecialBets');
    dispatch('checkPayin');
    dispatch('setPreball', false);
  },
  updateNotifications({ commit, dispatch, getters }, data) {
    clearTimeout(notificationTimer);
    const timeForRemoveNotification = data.duration || getters.timeForRemoveNotification;
    commit(mutationTypes.UPDATE_NOTIFICATIONS, data);
    if (data.status !== 'resolving') {
      dispatch('lockBetting', false);
      if (data.status === 'success') {
        dispatch('emptyBetslip');
      }
      notificationTimer = setTimeout(() => {
        commit(mutationTypes.HIDE_NOTIFICATION, data);
      }, timeForRemoveNotification);
    } else {
      dispatch('lockBetting', true);
    }
  },
  quickPayin({ dispatch, commit }, bet) {
    commit(mutationTypes.QUICK_PAYIN, bet);
    dispatch('payin');
  },
  payin({ dispatch, getters }) {
    // tooglePayinAction (inside GamesClientBetslip package) => disable payin button
    dispatch('togglePayinAction', true);
    const {
      ticket,
      player,
      appConfig,
      translations,
    } = getters;
    const token = getters.thirdPartyToken;
    const platform = getters.platforms[appConfig.platform.name][serverEnv];
    ticket.payin = getters.totalPayment;
    if (getters.payinDisabled) return;
    if (getters.requestDisabled) return;
    dispatch('allowRequest', true);
    if (appConfig.platform.name === 'seven') {
      api.payinSevenTicket({
        ticket: getters.ticket,
        platform,
        player,
        appConfig,
        token,
        translations,
      }).then((response) => {
        dispatch('ticketCheck', response.data.requestUuid);
        dispatch('updateNotifications',
          {
            status: 'resolving',
            id: response.data,
          });
        dispatch('allowRequest', false);
        dispatch('togglePayinAction', false);
      })
        .catch((error) => {
          if (error.response) {
            dispatch('togglePayinAction', false);
            const errorData = error.response.data || {};
            const errorMessage = errorData.message ? errorData.message : 'general_not_verified_email';
            dispatch('updateNotifications',
              {
                status: 'error',
                message: errorMessage,
              });
          }
        });
    } else {
      api.payinTicket({
        ticket: getters.ticket,
        platform,
        player,
        appConfig,
      })
        .then((response) => {
          dispatch('updateNotifications',
            {
              status: 'resolving',
              id: response.data,
            });
          dispatch('allowRequest', false);
        })
        .catch((error) => {
          if (error.response) {
            dispatch('togglePayinAction', false);
            const errorData = error.response.data;
            errorData.id = null;
            errorData.requiestUuid = null;
            dispatch('updateNotifications', errorData);
          }
        });
    }
  },
  checkPayin({ commit }, disabled) {
    commit(mutationTypes.CHECK_PAYIN, disabled);
  },
  setStats({ commit }, data) {
    commit(mutationTypes.SET_PRODUCT_STATS, data);
  },
  setResults({ commit }, data) {
    commit(mutationTypes.SET_PRODUCT_RESULTS, data);
  },
  getStats({ dispatch, getters }) {
    const config = getters.appConfig;
    api.getStats(config).then((response) => {
      dispatch('setStats', response.data);
    });
  },
  getResults({ dispatch, getters }) {
    const config = getters.appConfig;
    api.getResults(config).then((response) => {
      dispatch('setResults', response.data);
    });
  },
  setTranslations({ commit }, translations) {
    commit(mutationTypes.SET_TRANSLATIONS, translations);
  },
  setPreball({ commit }, preball) {
    commit(mutationTypes.CREATE_PREBALL, preball);
  },
  allowRequest({ commit }, allow) {
    commit(mutationTypes.ALLOW_REQUEST, allow);
  },
  toggleTicketHistory({ commit }) {
    commit(mutationTypes.TOGGLE_TICKET_HISTORY);
  },
  rebet({ commit }, ticket) {
    commit(mutationTypes.REBET, ticket);
  },
  closeErrorOverlay({ commit }) {
    commit(mutationTypes.CLOSE_ERROR_OVERLAY);
  },
  setLastTicketsLoader({ commit }, inProgress) {
    commit(mutationTypes.LAST_TICKETS_IN_PROGRESS, inProgress);
  },
  setTicketHistoryLoader({ commit }, inProgress) {
    commit(mutationTypes.TICKET_HISTORY_IN_PROGRESS, inProgress);
  },
  loadLastTickets({ dispatch, commit, getters }) {
    let tickets = [];
    const config = assign(getters.appConfig, getters.player);
    const { player } = getters;
    if (player.loggedIn) {
      dispatch('setLastTicketsLoader', true);
      api.getLastTickets(config).then((response) => {
        tickets = response.data;
        each(tickets, (ticket) => {
          each(ticket.bets, (ticketBet) => {
            const bet = ticketBet;
            const specialBet = getters.specialBets.filter((special) => special.id === bet.type)[0];
            // We need this for firstBallColor bet
            const colors = resolveColors(bet.value);

            if (!bet.outcome) {
              bet.outcome = {
                value: bet.value.split(','),
              };
              if (specialBet && specialBet.id === 5) {
                bet.outcome.title = formatTitle(specialBet, colors);
              } else if (specialBet && specialBet.id !== 5) {
                bet.outcome.title = specialBet.outcomes[Number(bet.value)].title;
              } else {
                bet.outcome.title = bet.value.split(',');
              }
            }
          });
        });
        commit(mutationTypes.LAST_TICKETS, tickets);
        dispatch('setLastTicketsLoader', false);
      });
    } else {
      commit(mutationTypes.LAST_TICKETS, tickets);
    }
  },
  loadTicketHistory({ commit, getters, dispatch }, filters) {
    commit('SET_TICKET_HISTORY_RANGE_ERROR', {});
    const config = assign(getters.appConfig, getters.player);
    const { player } = getters;
    if (player.loggedIn) {
      dispatch('setTicketHistoryLoader', true);
      api.getTicketHistory(config, filters).then((response) => {
        dispatch('setTicketHistoryLoader', false);
        const tickets = response.data;
        each(tickets, (ticket) => {
          each(ticket.bets, (ticketBet) => {
            const bet = ticketBet;
            const specialBet = getters.specialBets.filter((special) => special.id === bet.type)[0];
            // We need this for firstBallColor bet
            const colors = resolveColors(bet.value);

            if (!bet.outcome) {
              bet.outcome = {
                value: bet.value.split(','),
              };
              if (specialBet && specialBet.id === 5) {
                bet.outcome.title = formatTitle(specialBet, colors);
              } else if (specialBet && specialBet.id !== 5) {
                bet.outcome.title = specialBet.outcomes[Number(bet.value)].title;
              } else {
                bet.outcome.title = bet.value.split(',');
              }
            }
          });
        });
        commit(mutationTypes.TICKET_HISTORY, tickets);
      }, (error) => {
        dispatch('setTicketHistoryLoader', false);
        commit(mutationTypes.TICKET_HISTORY, []);
        if (error.response) {
          const errorData = error.response.data;
          errorData.id = null;
          errorData.requiestUuid = null;
          dispatch('setTicketHistoryRangeError', errorData);
        }
      });
    }
  },
  setTicketHistoryRangeError({ commit }, errorData) {
    commit('SET_TICKET_HISTORY_RANGE_ERROR', errorData);
  },
  showHideTicketHistory({ commit }) {
    commit(mutationTypes.SHOW_HIDE_TICKET_HISTORY);
  },
  setSpecialsBetVisibility({ commit }, bets) {
    commit(mutationTypes.SET_SPECIAL_BET_VISIBILITY, bets);
  },
  setSpecialBetOdds({ commit }, bets) {
    commit(mutationTypes.SET_SPECIAL_BET_ODDS, bets);
  },
  setPreballBetOdd({ commit }, bets) {
    commit(mutationTypes.SET_PREBALL_BET_ODD, bets);
  },
  rebetTicket({ dispatch }, ticket) {
    const ticketData = ticket;
    dispatch('emptyBetslip');
    ticketData.rebet = true;
    dispatch('addRebetToBetslip', ticketData);
    dispatch('updateTicketRoundInfo');
  },
  cancelTicket({ dispatch, getters }, ticket) {
    const config = assign(getters.appConfig, getters.player);
    const { appConfig, player } = getters;
    if (config.platform.name === 'seven') {
      api.cancelSevenTicket({ appConfig, player, ticket }).then((response) => {
        if ([200, 202].includes(response.status)) {
          dispatch('loadTicketHistory');
        }
      });
    } else {
      api.cancelPlaytechTicket(config, ticket).then((response) => {
        if ([200, 202].includes(response.status)) {
          dispatch('loadTicketHistory');
        }
      });
    }
  },
  bettingDisabledEvent({ commit, getters, dispatch }, disabled) {
    commit(mutationTypes.BETTING_DISABLED_EVENT, disabled);
    if (disabled) {
      dispatch('updateNotifications', {
        status: 'error',
        message: getters.translations.general_betting_disabled_warning,
        duration: getters.ticketBlockTime,
      });
    }
  },
  loadPlugins({ commit }, plugins) {
    commit(mutationTypes.LOAD_PLUGINS, plugins);
  },
  toggleAudio({ commit }) {
    commit(mutationTypes.TOGGLE_AUDIO);
  },
  setDrawnBalls({ commit }, payload) {
    commit(mutationTypes.SET_DRAWN_BALLS, payload);
  },
  ballDrawn({ getters, dispatch }, data) {
    each(getters.lastTicketsData, (ticket) => {
      if (ticket.status.value === 'IN_PLAY' || ticket.status.value === 'OPEN') {
        dispatch('setDrawnBalls', data);
      }
    });
  },
  toggleStats({ commit }) {
    commit(mutationTypes.TOGGLE_STATS);
  },
  lockBetting({ commit }, locked) {
    commit(mutationTypes.LOCK_BETTING, locked);
  },
  setFullscreenStatus({ commit }, fullScreen) {
    commit(mutationTypes.SET_FULL_SCREEN_STATUS, fullScreen);
  },
  setLocalizedTranslations({ commit }, translations) {
    commit('SET_LOCALIZED_TRANSLATIONS', translations);
  },
  toggleNewFeatures({ commit }) {
    commit(mutationTypes.TOGGLE_NEW_FEATURES);
  },
  clearCheckTimeout() {
    clearTimeout(check);
  },
  ticketCheck({ dispatch, getters }, requestUuid) {
    const { appConfig, player } = getters;
    check = setTimeout(() => {
      api.ticketCheck(appConfig, player, requestUuid).then((response) => {
        if (response.data.ticket.status.value !== 'PENDING') {
          dispatch('updateNotifications',
            {
              status: 'success',
              message: getters.translations.general_ticket_confirmed,
            });
        }
        clearTimeout(check);
      });
    }, checkTimeout);
  },
  authUser({ dispatch }, {
    config,
    token,
    id,
    authStrategy,
  }) {
    api.authSevenUser(config, token, id, authStrategy).then((response) => {
      if (response) {
        const currentToken = response.headers['access-token'];
        Promise.all([
          api.sevenLoginCheck(config, currentToken),
          api.getSevenBalance(config, currentToken),
        ]).then((responses) => {
          dispatch('updatePlayerInfo', assign(responses[0].data, {
            id: responses[0].data.Uuid,
            token: currentToken,
            balance: responses[1].data.balance,
          }));
        });
      }
    });
  },
  setThirdPartyToken({ commit }, token) {
    commit(mutationTypes.SET_THIRD_PARTY_TOKEN, token);
  },
  setIsMobile({ commit }, isMobile) {
    commit(mutationTypes.SET_IS_MOBILE, isMobile);
  },
};
